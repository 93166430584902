export const LOCAL_SHIPMENT_STATUSES = {
  UPCOMING: 'upcoming',
  ALL: 'all',
  PLANNED: 'planned',
  AT_WAREHOUSE: 'at-warehouse',
  IN_TRANSIT: 'in-transit',
  AT_LOCATION: 'at-location',
  ONGOING: 'ongoing',
  COMPLETED: 'completed',
  HISTORY: 'history'
};
export const TRACKER_EVENTS = {
  BULK_DO_CLICKED: 'Bulk DO clicked',
  BULK_DO_SUBMITTED: 'Bulk DO submitted',
  CHOOSE_COMPANY: 'Choose company',
  DO_DETAIL_ASSIGN: 'DO detail assign',
  DO_LIST_ASSIGN: 'DO list assign',
  DO_LIST_EXPANDED: 'DO list expanded',
  DO_LIST_REASSIGN: 'DO list reassign',
  DOWNLOAD_INSIGHTS_CSV: 'Download insights CSV',
  INSIGHTS_FILTER_CHANGED: 'Insight filter changed',
  INSIGHTS_CHART_TARGET_CHANGED: 'Insight chart target changed',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  MAP_MARKER_SELECTED: 'Map marker selected',
  MAP_SEARCH_KEYWORD_APPLIED: 'Map searchKeyword filter applied',
  MAP_SHIPMENT_DIRECTION_APPLIED: 'Map shipment direction filter applied',
  MAP_STATUS_APPLIED: 'Map status filter applied',
  MAP_WAREHOUSE_APPLIED: 'Map warehouse filter applied',
  NAVIGATION_CHANGED: 'Navigation changed',
  ONSITE_NOTIF_OPENED: 'Onsite notification opened',
  SINGLE_DO_CLICKED: 'Single DO clicked',
  SINGLE_DO_SUBMITTED: 'Single DO Submitted',
  FILTER_STATUS: 'Filter Status',
  FILTER_PICKUP_LOCATION: 'Filter pickup location',
  FILTER_DROPOFF_LOCATION: 'Filter dropoff location',
  FILTER_PICKUP_DATE: 'Filter pickup date time',
  FILTER_DROPOFF_DATE: 'Filter dropoff date time',
  EXPAND_SHIPMENT: 'click expand shipment',
  OPEN_SHIPMENT_DETAIL: 'Open shipment detail',
  OPEN_SHIPMENT_LIST: 'Open shipment list page',
  OPEN_SHIPMENT_TRACKING_LINK: 'Open shipment from sharing link',
  OPEN_TAB: 'Open shipment list tab',
  CLICK_CONTACT_US: 'Click Hubungi Kami in shipment detail'
};
export const SHIPPER_PLATFORM_WHITELIST = {
  ALLOW_LOGIN: 'ALLOW_LOGIN',
  ALLOW_FIRST_TIME_LOGIN: 'ALLOW_FIRST_TIME_LOGIN',
  NOT_ALLOWED_TO_LOGIN: 'NOT_ALLOWED_TO_LOGIN'
};
export const COMPANY_TYPES = {
  PT: 'PT',
  UD: 'UD',
  CV: 'CV',
  FA: 'FA'
};
export const OTP_OPTIONS = {
  REGISTRATION: 'REGISTRATION',
  RESET_PASSWORD: 'RESET_PASSWORD',
  FIRST_TIME_LOGIN: 'FIRST_TIME_LOGIN'
};
export const LOCATION_TYPE = {
  NORMAL: 'NORMAL',
  HOLDING: 'HOLDING',
  PORT: 'PORT',
  RETURN_DEPO: 'RETURN_DEPO',
  STORE: 'STORE'
};
export const ROUTE_LOCATION_GROUP = {
  PORT_STORE_RETURN_DEPO: [LOCATION_TYPE.PORT, LOCATION_TYPE.STORE, LOCATION_TYPE.RETURN_DEPO],
  PORT_HOLDING: [LOCATION_TYPE.PORT, LOCATION_TYPE.HOLDING],
  HOLDING_STORE_RETURN_DEPO: [LOCATION_TYPE.HOLDING, LOCATION_TYPE.STORE, LOCATION_TYPE.RETURN_DEPO]
};
export const SERVICES_URL = {
  PANTHERA: process.env.REACT_APP_PANTHERA_SERVICE_URL,
  LOAD: process.env.REACT_APP_LOAD_SERVICE_URL,
  EVENT: process.env.REACT_APP_EVENT_SERVICE_URL,
  PROFILE: process.env.REACT_APP_PROFILE_SERVICE_URL,
  MASTER_SERVICE: process.env.REACT_APP_MASTER_SERVICE_URL,
  AUTH: process.env.REACT_APP_AUTH_SERVICE_URL,
  PROFILE_PUBLIC: process.env.REACT_APP_PROFILE_SERVICE_URL_PUBLIC,
  PAYMENT: process.env.REACT_APP_PAYMENT_SERVICE_URL
};
export const EVENT_SERVICE_DEVICE_TYPE = 'transporter_central';
export const USER_STATUSES = {
  ACTIVE: 'ACTIVE',
  REGISTERED: 'REGISTERED',
  INACTIVE: 'INACTIVE'
};
export const USER_STATUS_COLORS = {
  [USER_STATUSES.ACTIVE]: 'success',
  [USER_STATUSES.REGISTERED]: 'warning',
  [USER_STATUSES.INACTIVE]: 'danger'
};
export const NON_EDITABLE_USER_STATUSES = [USER_STATUSES.BLOCKED];
export const DOCUMENT_TYPES = {
  POD: 'POD',
  POL: 'POL',
  CLAIMS_AND_RETURNS: 'CAR',
  CLAIM_AND_RETURN_DOCUMENT: 'CLAIM_AND_RETURN_DOCUMENT',
  CLAIM_AND_RETURN_GOODS: 'CLAIM_AND_RETURN_GOODS',
  DRIVER_REJECTED_GOODS: 'REJECTED_GOODS',
  DRIVER_REJECTION_LETTER: 'REJECTION_LETTER'
};
export const SHIPMENT_DOCUMENT_TYPES = {
  INTERNAL_POD: 'internal-pod',
  INTERNAL_POL: 'internal-pol',
  CLAIMS_AND_RETURNS: 'claims-returns'
};
export const CUSTOMER_DIVISION = {
  OPERATIONS: 'OPERATIONS',
  WAREHOUSE: 'WAREHOUSE',
  SALES: 'SALES',
  FINANCE: 'FINANCE',
  PROCUREMENT: 'PROCUREMENT',
  LOGISTICS: 'LOGISTICS',
  TREASURY: 'TREASURY',
  CLAIMS: 'CLAIMS'
};
export const WEIGHT_UNITS = {
  TON: 'TON',
  CBM: 'CBM'
};
export const PPH23_DEDUCTOR = {
  PARTNER: 'PARTNER',
  SELF: 'SELF'
};
export const SHIPMENT_FORM_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  ALLOCATE: 'ALLOCATE',
  REALLOCATE: 'REALLOCATE',
  UPDATE_DO_NUMBER: 'UPDATE_DO_NUMBER',
  CHOOSE_WINNER: 'CHOOSE_WINNER',
  ASSIGN_SHIPMENT: 'ASSIGN_SHIPMENT',
  CREATE_REMINDER: 'CREATE_REMINDER',
  REOPEN_JOB: 'REOPEN_JOB',
  CANCEL_SHIPMENT: 'CANCEL_SHIPMENT',
  UNFULFILLMENT_SHIPMENT: 'UNFULFILLMENT_SHIPMENT',
  CANCEL_MARKETPLACE_JOB: 'CANCEL_MARKETPLACE_JOB',
  APPROVE_REASSIGNMENT: 'APPROVE_REASSIGNMENT',
  SET_BID_BASE_PRICE: 'SET_BID_BASE_PRICE',
  SET_BID_WINNER: 'SET_BID_WINNER',
  CREATE_TEMPLATE: 'CREATE_TEMPLATE',
  EDIT_TEMPLATE: 'EDIT_TEMPLATE'
};
export const SHIPMENT_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  ALLOCATION_FORM: 'ALLOCATION_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY',
  TEMPLATE_SELECTION: 'TEMPLATE_SELECTION'
};
export const SHIPMENT_STATUSES = {
  UPCOMING: 'UPCOMING',
  PLANNED: 'PLANNED',
  ASSIGNED: 'ASSIGNED',
  ONGOING_TO_PICKUP: 'ONGOING_TO_PICKUP',
  ARRIVE_AT_PICKUP: 'ARRIVE_AT_PICKUP',
  START_LOADING: 'START_LOADING',
  FINISH_LOADING: 'FINISH_LOADING',
  ONGOING_TO_DESTINATION: 'ONGOING_TO_DESTINATION',
  ON_SHIPMENT: 'ON_SHIPMENT',
  ARRIVE_AT_DESTINATION: 'ARRIVE_AT_DESTINATION',
  START_UNLOADING: 'START_UNLOADING',
  FINISH_UNLOADING: 'FINISH_UNLOADING',
  DOCUMENT_UPLOADED: 'DOCUMENT_UPLOADED',
  SHIPMENT_COMPLETE: 'SHIPMENT_COMPLETE',
  CANCELLED_BY_SHIPPER: 'CANCELLED_BY_SHIPPER',
  CANCELLED_BY_TRANSPORTER: 'CANCELLED_BY_TRANSPORTER',
  CANCELLED: 'CANCELLED',
  UNFULFILLED: 'UNFULFILLED',
  REASSIGNED_BY_SHIPPER: 'REASSIGNED_BY_SHIPPER',
  REJECTED_BY_TRANSPORTER: 'REJECTED_BY_TRANSPORTER',
  CONFIRMATION_EXPIRED: 'CONFIRMATION_EXPIRED',
  WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',
  JOB_TRANSPORTER_CONFIRMED: 'JOB_TRANSPORTER_CONFIRMED',
  CANCELLED_BY_INTERNAL: 'CANCELLED_BY_INTERNAL',
  WAITING_FOR_JOB_CONFIRMATION: 'WAITING_FOR_JOB_CONFIRMATION',
  JOB_UNASSIGNED: 'JOB_UNASSIGNED',
  JOB_BIDDING: 'JOB_BIDDING',
  WAITING_FOR_TRUCK: 'WAITING_FOR_TRUCK',
  TRUCK_CONFIRMED: 'TRUCK_CONFIRMED',
  SHIPMENT_CANCELLED: 'SHIPMENT_CANCELLED'
};
export const SHIPMENT_ACTIVITY_TYPES = {
  ASSIGNED_ONGOING_TO_PICKUP: 'ASSIGNED_ONGOING_TO_PICKUP',
  ONGOING_TO_PICKUP_ARRIVE_AT_PICKUP: 'ONGOING_TO_PICKUP_ARRIVE_AT_PICKUP',
  ARRIVE_AT_PICKUP_START_LOADING: 'ARRIVE_AT_PICKUP_START_LOADING',
  START_LOADING_FINISH_LOADING: 'START_LOADING_FINISH_LOADING',
  FINISH_LOADING_ON_SHIPMENT: 'FINISH_LOADING_ON_SHIPMENT',
  ON_SHIPMENT_ARRIVE_AT_DESTINATION: 'ON_SHIPMENT_ARRIVE_AT_DESTINATION',
  ARRIVE_AT_DESTINATION_START_UNLOADING: 'ARRIVE_AT_DESTINATION_START_UNLOADING'
};
export const DERIVED_SHIPMENT_STATUSES = {
  MAIN_SHIPMENT: 'MAIN_SHIPMENT',
  BEHIND_THE_SCHEDULE: 'BEHIND_THE_SCHEDULE',
  ON_TIME: 'ON_TIME',
  QUEUEING_AT_ORIGIN: 'QUEUEING_AT_ORIGIN',
  QUEUEING_AT_DESTINATION: 'QUEUEING_AT_DESTINATION',
  QUEUEING_OVERTIME: 'QUEUEING_OVERTIME',
  CANCELLED_BY_INTERNAL: 'CANCELLED_BY_INTERNAL',
  JOB_BIDDING: 'JOB_BIDDING',
  JOB_WAITING_FOR_PRICE_INPUT: 'JOB_WAITING_FOR_PRICE_INPUT'
};
export const SHIPMENT_COUNT_QUERY_NAME = {
  [SHIPMENT_STATUSES.UPCOMING]: 'upcomingCount',
  [SHIPMENT_STATUSES.PLANNED]: 'plannedCount',
  [SHIPMENT_STATUSES.ASSIGNED]: 'assignedCount',
  [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'arriveAtPickupCount',
  [SHIPMENT_STATUSES.START_LOADING]: 'startLoadingCount',
  [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'arriveAtDestinationCount',
  [SHIPMENT_STATUSES.START_UNLOADING]: 'startUnloadingCount',
  [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'finishUnloadingCount',
  [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'shipmentCompleteCount',
  [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'cancelledByTransporterCount',
  [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'cancelledByShipperCount',
  [SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'cancelledByInternalCount',
  [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'queueingOvertimeCount',
  [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'behindTheScheduleCount',
  [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'onTimeCount',
  [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'confirmationExpiredCount',
  [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'onGoingToPickupCount',
  [SHIPMENT_STATUSES.ONGOING_TO_DESTINATION]: 'ongoingToDestinationCount'
};
export const SHIPMENT_STATUS_COUNTS_LOCAL_GROUPS = {
  [LOCAL_SHIPMENT_STATUSES.PLANNED]: [SHIPMENT_STATUSES.UPCOMING, SHIPMENT_STATUSES.PLANNED],
  [LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE]: [
    DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME,
    SHIPMENT_STATUSES.START_LOADING
  ],
  [LOCAL_SHIPMENT_STATUSES.AT_LOCATION]: [
    DERIVED_SHIPMENT_STATUSES.QUEUE_AT_ORIGIN_LATE,
    DERIVED_SHIPMENT_STATUSES.QUEUE_AT_DESTINATION_LATE
  ],
  [LOCAL_SHIPMENT_STATUSES.IN_TRANSIT]: [
    DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE,
    DERIVED_SHIPMENT_STATUSES.ON_TIME
  ],
  [LOCAL_SHIPMENT_STATUSES.ONGOING]: [
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.LATE_TO_ORIGIN,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.LATE_TO_DESTINATION
  ],
  [LOCAL_SHIPMENT_STATUSES.COMPLETED]: [
    SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER,
    SHIPMENT_STATUSES.SHIPMENT_COMPLETE
  ],
  [LOCAL_SHIPMENT_STATUSES.ALL]: [SHIPMENT_STATUSES.UPCOMING, SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]
};
export const SHIPMENT_STATUS_LOCAL_GROUPS = {
  [LOCAL_SHIPMENT_STATUSES.UPCOMING]: [SHIPMENT_STATUSES.UPCOMING],
  [LOCAL_SHIPMENT_STATUSES.PLANNED]: ({
    USER_SHIPPER: [
      SHIPMENT_STATUSES.UPCOMING,
      DERIVED_SHIPMENT_STATUSES.JOB_WAITING_FOR_PRICE_INPUT,
      DERIVED_SHIPMENT_STATUSES.JOB_BIDDING,
      SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER,
      SHIPMENT_STATUSES.CONFIRMATION_EXPIRED,
      SHIPMENT_STATUSES.PLANNED,
      SHIPMENT_STATUSES.ASSIGNED
    ],
    SHIPPER_PLATFORM: [SHIPMENT_STATUSES.TRUCK_CONFIRMED, SHIPMENT_STATUSES.WAITING_FOR_TRUCK]
  })[process.env.REACT_APP_CLIENT_MODE] || [],
  [LOCAL_SHIPMENT_STATUSES.AT_LOCATION]: [
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.START_LOADING,
    SHIPMENT_STATUSES.FINISH_LOADING,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.START_UNLOADING,
    SHIPMENT_STATUSES.FINISH_UNLOADING
  ],
  [LOCAL_SHIPMENT_STATUSES.HISTORY]: [SHIPMENT_STATUSES.SHIPMENT_COMPLETE, SHIPMENT_STATUSES.SHIPMENT_CANCELLED],
  [LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE]: [
    SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
    SHIPMENT_STATUSES.START_LOADING,
    SHIPMENT_STATUSES.FINISH_LOADING,
    SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
    SHIPMENT_STATUSES.START_UNLOADING,
    SHIPMENT_STATUSES.FINISH_UNLOADING
  ],
  [LOCAL_SHIPMENT_STATUSES.IN_TRANSIT]: [SHIPMENT_STATUSES.ONGOING_TO_PICKUP, SHIPMENT_STATUSES.ON_SHIPMENT],
  [LOCAL_SHIPMENT_STATUSES.ONGOING]: ({
    USER_SHIPPER: [],
    SHIPPER_PLATFORM: [
      SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
      SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
      SHIPMENT_STATUSES.START_LOADING,
      SHIPMENT_STATUSES.FINISH_LOADING,
      SHIPMENT_STATUSES.ON_SHIPMENT,
      SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
      SHIPMENT_STATUSES.START_UNLOADING,
      SHIPMENT_STATUSES.FINISH_UNLOADING,
      SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION
    ]
  })[process.env.REACT_APP_CLIENT_MODE] || [],
  [LOCAL_SHIPMENT_STATUSES.COMPLETED]: [SHIPMENT_STATUSES.SHIPMENT_COMPLETE, SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER],
  get [LOCAL_SHIPMENT_STATUSES.ALL]() {
    return [
      ...this[LOCAL_SHIPMENT_STATUSES.PLANNED],
      ...this[LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE],
      ...this[LOCAL_SHIPMENT_STATUSES.IN_TRANSIT],
      ...this[LOCAL_SHIPMENT_STATUSES.COMPLETED]];
  }
};
export const JOB_STATUSES = {
  OPEN: 'OPEN',
  WAITING_FOR_CONFIRMATION: 'WAITING_FOR_CONFIRMATION',
  JOB_BIDDING: 'JOB_BIDDING',
  JOB_UNASSIGNED: 'JOB_UNASSIGNED',
  LATE_TO_ORIGIN: 'LATE_TO_ORIGIN',
  LATE_TO_DESTINATION: 'LATE_TO_DESTINATION',
  JOB_TRANSPORTER_CONFIRMED: 'JOB_TRANSPORTER_CONFIRMED',
  WAITING_FOR_JOB_CONFIRMATION: 'WAITING_FOR_JOB_CONFIRMATION',
  TRANSPORTER_CONFIRMED: 'TRANSPORTER_CONFIRMED',
  BIDDING: 'BIDDING',
  CANCELLED: 'CANCELLED',
  PLANNED: 'PLANNED',
  ASSIGNED: 'ASSIGNED',
  UNASSIGNED: 'UNASSIGNED',
  CANCELLED_BY_TRANSPORTER: 'CANCELLED_BY_TRANSPORTER',
  CONFIRMATION_EXPIRED: 'CONFIRMATION_EXPIRED'
};
export const SHIPMENT_STATUS_SEQUENCE = [
  SHIPMENT_STATUSES.UPCOMING,
  SHIPMENT_STATUSES.PLANNED,
  SHIPMENT_STATUSES.ASSIGNED,
  SHIPMENT_STATUSES.ONGOING_TO_PICKUP,
  SHIPMENT_STATUSES.ARRIVE_AT_PICKUP,
  SHIPMENT_STATUSES.START_LOADING,
  SHIPMENT_STATUSES.FINISH_LOADING,
  SHIPMENT_STATUSES.ON_SHIPMENT,
  SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION,
  SHIPMENT_STATUSES.START_UNLOADING,
  SHIPMENT_STATUSES.FINISH_UNLOADING,
  SHIPMENT_STATUSES.SHIPMENT_COMPLETE
];
export const SHIPPER_LOCATION_OPERATING_DAY = {
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
  WEEKDAY: 'WEEKDAY',
  WEEKEND: 'WEEKEND',
  HOLIDAY: 'HOLIDAY'
};
export const SHIPMENT_STATUS_COLORS = {
  [SHIPMENT_STATUSES.UPCOMING]: 'danger',
  [SHIPMENT_STATUSES.PLANNED]: 'darkGrey',
  [SHIPMENT_STATUSES.ASSIGNED]: 'darkGrey',
  [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'primary',
  [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'primary',
  [SHIPMENT_STATUSES.START_LOADING]: 'warning',
  [SHIPMENT_STATUSES.FINISH_LOADING]: 'warning',
  [SHIPMENT_STATUSES.ON_SHIPMENT]: 'primary',
  [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'primary',
  [SHIPMENT_STATUSES.START_UNLOADING]: 'warning',
  [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'warning',
  [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'success',
  [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'danger',
  [SHIPMENT_STATUSES.CANCELLED]: 'danger',
  [SHIPMENT_STATUSES.UNFULFILLED]: 'danger',
  [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'danger',
  [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'danger',
  [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'danger',
  [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'danger',
  [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'danger',
  [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'danger',
  [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'primary',
  [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'danger',
  [SHIPMENT_STATUSES.JOB_UNASSIGNED]: 'darkGrey',
  [SHIPMENT_STATUSES.JOB_BIDDING]: 'darkGrey',
  [SHIPMENT_STATUSES.WAITING_FOR_JOB_CONFIRMATION]: 'darkGrey',
  [SHIPMENT_STATUSES.JOB_TRANSPORTER_CONFIRMED]: 'darkGrey',
  [SHIPMENT_STATUSES.UPCOMING]: 'darkGrey',
  [SHIPMENT_STATUSES.PLANNED]: 'darkGrey',
  [SHIPMENT_STATUSES.ASSIGNED]: 'primary',
  [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'warning',
  [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'warning',
  [SHIPMENT_STATUSES.START_LOADING]: 'warning',
  [SHIPMENT_STATUSES.FINISH_LOADING]: 'warning',
  [SHIPMENT_STATUSES.ON_SHIPMENT]: 'warning',
  [SHIPMENT_STATUSES.ONGOING_TO_DESTINATION]: 'success',
  [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'warning',
  [SHIPMENT_STATUSES.START_UNLOADING]: 'warning',
  [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'warning',
  [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'success',
  [SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION]: 'warning',
  [SHIPMENT_STATUSES.CANCELLED]: 'danger',
  [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'danger',
  [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'danger',
  [SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'danger',
  [SHIPMENT_STATUSES.UNFULFILLED]: 'danger'
};
export const MORE_FILTER_KEYS = {
  ORIGIN_LOCATION_FIELD: 'originWarehouseKsuid',
  DESTINATION_LOCATION_FIELD: 'destinationWarehouseKsuid',
  LOADING_DATE_FIELD: 'loadingDate',
  UNLOADING_DATE_FIELD: 'unloadingDate',
  ARRIVE_AT_ORIGIN_DATE_FIELD: 'arriveAtOriginDate',
  ARRIVE_AT_DESTINATION_DATE_FIELD: 'arriveAtDestinationDate',
  TAGS_FIELD: 'tags',
  SUPPORT_FIELD: 'createdByKsuid',
  EXTERNAL_ID: 'externalId',
  GROUP_LOAD_NUMBER: 'groupLoadNumber',
  TRANSPORTER_COMPANY: 'transporterCompanyKsuids',
  DRIVER: 'driverKsuids'
};
export const COMPANY_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  REGISTERED: 'REGISTERED',
  CHURN: 'CHURN',
  DEACTIVATED: 'DEACTIVATED'
};
export const COMPANY_STATUS_COLORS = {
  [COMPANY_STATUSES.ACTIVE]: 'success',
  [COMPANY_STATUSES.INACTIVE]: 'danger',
  [COMPANY_STATUSES.REGISTERED]: 'orangeyYellow',
  [COMPANY_STATUSES.CHURN]: 'danger',
  [COMPANY_STATUSES.DEACTIVATED]: 'danger'
};
const LOCATION_STATUSES = {
  REGISTERED: 'REGISTERED',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};
export const LOCATION_STATUS_COLORS = {
  [LOCATION_STATUSES.REGISTERED]: 'success',
  [LOCATION_STATUSES.ACTIVE]: 'success',
  [LOCATION_STATUSES.INACTIVE]: 'danger'
};
const SHIPPER_PLATFORM_ACCESS_TYPE = {
  LOGISTIC_PIC: 'LOGISTIC_PIC',
  MONITORING_PIC: 'MONITORING_PIC',
  KARGO_SHIPPER_SALES: 'KARGO_SHIPPER_SALES',
  SHIPPER_PLATFORM: 'SHIPPER_PLATFORM'
};
export const USER_ACCESS_TYPE = {
  ADMIN: 'ADMIN',
  FULL_ACCESS: 'FULL_ACCESS',
  RESTRICTED_ACCESS: 'RESTRICTED_ACCESS',
  ...process.env.REACT_APP_CLIENT_MODE === 'SHIPPER_PLATFORM' ? SHIPPER_PLATFORM_ACCESS_TYPE : {}
};
export const FILE_UPLOAD_SOURCE = {
  DA: 'DA',
  TMS: 'TMS'
};
export const FILE_PREVIEW_TYPE = {
  IMAGE: 'IMAGE',
  GDRIVE_FOLDER: 'GDRIVE_FOLDER',
  GDRIVE_FILE: 'GDRIVE_FILE',
  PDF: 'PDF'
};
export const FIRST_MILE_USER_ACCESS_TYPE = {
  FREIGHT_FORWARDER: 'FREIGHT_FORWARDER',
  CONSIGNEE: 'CONSIGNEE'
};
export const CONTAINER_FORM_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  BATCH_UPDATE: 'BATCH_UPDATE'
};
export const CONTAINER_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY',
  BATCH_UPDATE_FORM: 'BATCH_UPDATE_FORM'
};
export const CONTAINER_STATUSES = {
  ARRIVED_AT_PORT: 'ARRIVED_AT_PORT',
  PIB: 'PIB',
  NO_NOA: 'NO_NOA',
  NOA: 'NOA',
  SPPB: 'SPPB',
  WAITING_FOR_SPPB: 'WAITING_FOR_SPPB',
  WAITING_FOR_ORIGINAL_DOC: 'WAITING_FOR_ORIGINAL_DOC',
  QUARANTINED: 'QUARANTINED',
  ON_SHIPMENT: 'ON_SHIPMENT',
  MOVED_TO_HOLDING: 'MOVED_TO_HOLDING',
  SHIPMENT_COMPLETE: 'SHIPMENT_COMPLETE',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  INVOICED: 'INVOICED',
  PAID: 'PAID'
};
export const CONTAINER_STATUS_COLOR = {
  [CONTAINER_STATUSES.ARRIVED_AT_PORT]: 'primary',
  [CONTAINER_STATUSES.ON_SHIPMENT]: 'primary',
  [CONTAINER_STATUSES.SHIPMENT_COMPLETE]: 'success',
  [CONTAINER_STATUSES.PAYMENT_PENDING]: 'warning',
  [CONTAINER_STATUSES.WAITING_FOR_SPPB]: 'warning',
  [CONTAINER_STATUSES.WAITING_FOR_ORIGINAL_DOC]: 'warning',
  [CONTAINER_STATUSES.PAID]: 'success',
  [CONTAINER_STATUSES.MOVED_TO_HOLDING]: 'primary',
  [CONTAINER_STATUSES.PIB]: 'primary',
  [CONTAINER_STATUSES.NO_NOA]: 'warning',
  [CONTAINER_STATUSES.NOA]: 'primary',
  [CONTAINER_STATUSES.SPPB]: 'primary',
  [CONTAINER_STATUSES.QUARANTINED]: 'primary',
  [CONTAINER_STATUSES.INVOICED]: 'success'
};
export const FIRST_MILE_INVOICE_FORM_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE'
};
export const FIRST_MILE_INVOICE_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY'
};
export const FIRST_MILE_INVOICE_STATUSES = {
  DUE: 'DUE',
  OVERDUE: 'OVERDUE',
  VERY_OVERDUE: 'VERY_OVERDUE',
  PAID: 'PAID'
};
export const FIRST_MILE_INVOICE_STATUS_COLORS = {
  [FIRST_MILE_INVOICE_STATUSES.DUE]: 'primary',
  [FIRST_MILE_INVOICE_STATUSES.OVERDUE]: 'warning',
  [FIRST_MILE_INVOICE_STATUSES.VERY_OVERDUE]: 'danger',
  [FIRST_MILE_INVOICE_STATUSES.PAID]: 'success'
};
export const BILL_OF_LADING_FORM_TYPES = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE'
};
export const BILL_OF_LADING_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY'
};
export const FIRST_MILE_DOCUMENT_TYPES = {
  HOUSE_BILL_OF_LADING: 'HOUSE_BILL_OF_LADING',
  BILL_OF_LADING: 'BILL_OF_LADING',
  NOTICE_OF_ARRIVAL: 'NOTICE_OF_ARRIVAL',
  PIB: 'PIB',
  QUARANTINE: 'QUARANTINE',
  EBILLING: 'EBILLING',
  SHIPPING_LINE_DELIVERY_ORDER: 'SHIPPING_LINE_DELIVERY_ORDER',
  STORAGE_CHARGES: 'STORAGE_CHARGES',
  TILA: 'TILA',
  SPPB: 'SPPB',
  CONTAINER_RENTAL: 'CONTAINER_RENTAL',
  PACKING_LIST: 'PACKING_LIST',
  MATERIAL_SAFETY_AND_DATA_SHEET: 'MATERIAL_SAFETY_AND_DATA_SHEET',
  CERTIFICATE_OF_ORIGIN: 'CERTIFICATE_OF_ORIGIN',
  PROOF_OF_DELIVERY: 'PROOF_OF_DELIVERY',
  LOLO: 'LOLO',
  TAX_INVOICE: 'TAX_INVOICE',
  BILL_OF_LADING_INVOICE: 'BILL_OF_LADING_INVOICE'
};
export const CONTAINER_TYPES = {
  REEFER: 'REEFER',
  DRY: 'DRY'
};
export const FIRST_MILE_CONTAINER_SIZES = {
  '20_FEET': '20_FEET',
  '20_FR': '20_FR',
  '20_GP': '20_GP',
  '20_OT': '20_OT',
  '20_OW': '20_OW',
  '40_FEET': '40_FEET',
  '40_FR': '40_FR',
  '40_GP': '40_GP',
  '40_HC': '40_HC',
  '40_OW': '40_OW',
  '40_OT': '40_OT',
  '45_FEET': '45_FEET'
};
export const FIRST_MILE_COMPANY_CONSTANT_TYPES = {
  PORT: 'PORT',
  SUPPLIER: 'SUPPLIER',
  SHIPPING_LINE: 'SHIPPING_LINE',
  CONSIGNEE: 'CONSIGNEE',
  NOTIFY_PARTY: 'NOTIFY_PARTY',
  TERMINAL: 'TERMINAL'
};
export const FIRST_MILE_COMMODITIES = {
  'Memphis Raw Cotton': 'Memphis Raw Cotton',
  'American Raw Cotton': 'American Raw Cotton',
  'Brazillian Raw Cotton': 'Brazillian Raw Cotton',
  Machinery: 'Machinery',
  Chemicals: 'Chemicals',
  'Polyester Staple Fibre': 'Polyester Staple Fibre'
};
export const CUSTOMS_TYPES = {
  BC20: 'BC20',
  BC23: 'BC23',
  BC25: 'BC25'
};
export const EMPLOYEE_FORM_SEGMENTS = {
  DETAILS_FORM: 'DETAILS_FORM',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY'
};
export const SHIPMENT_COMPANY_CONSTANT_TYPES = {
  SHIPMENT_TYPE: 'SHIPMENT_TYPE',
  ORDER_TYPE: 'ORDER_TYPE'
};
export const APP_CONTEXT_STORAGE_KEY = 'appContext';
export const MULTIPLE_SHIPMENT_TYPE = {
  MULTIDROP: 'MULTIDROP',
  MULTIPICKUP: 'MULTIPICKUP',
  MULTIDROP_MULTIPICKUP: 'MULTIDROP_MULTIPICKUP'
};
export const DELIVERY_ORDER_WAREHOUSE_FILTER_TYPE = {
  INBOUND: 'inbound',
  OUTBOUND: 'outbound',
  ALL: 'all'
};
export const CUSTOM_CITY_REGION_MAPPING_METADATA = 'custom_city_region_mapping';
export const CUSTOM_CITY_CONSTANT_NAME = 'CITY';
export const CUSTOM_REGION_CONSTANT_NAME = 'REGION';
export const CUSTOM_LOCATION_GROUP_NAME = 'LOCATION_GROUP';
export const CUSTOM_QUANTITY_TYPE_NAME = 'QUANTITY_TYPE';
export const CUSTOM_PRODUCT_GROUP_NAME = 'PRODUCT_GROUP';
export const CUSTOM_COMPANY_ROLES_METADATA = 'company_roles';
export const DANONE_USER_ACCESS_TYPE = {
  DANONE_LOGISTIC: 'DANONE_LOGISTIC',
  DANONE_PROCUREMENT: 'DANONE_PROCUREMENT'
};
export const S_TMS_TRACKER_EVENTS = {
  BULK_DO_CLICKED: 'Bulk DO clicked',
  BULK_DO_SUBMITTED: 'Bulk DO submitted',
  CHOOSE_COMPANY: 'Choose company',
  DO_DETAIL_ASSIGN: 'DO detail assign',
  DO_LIST_ASSIGN: 'DO list assign',
  DO_LIST_EXPANDED: 'DO list expanded',
  DO_LIST_REASSIGN: 'DO list reassign',
  DOWNLOAD_INSIGHTS_CSV: 'Download insights CSV',
  INSIGHTS_FILTER_CHANGED: 'Insight filter changed',
  INSIGHTS_CHART_TARGET_CHANGED: 'Insight chart target changed',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  MAP_MARKER_SELECTED: 'Map marker selected',
  MAP_SEARCH_KEYWORD_APPLIED: 'Map searchKeyword filter applied',
  MAP_SHIPMENT_DIRECTION_APPLIED: 'Map shipment direction filter applied',
  MAP_STATUS_APPLIED: 'Map status filter applied',
  MAP_WAREHOUSE_APPLIED: 'Map warehouse filter applied',
  NAVIGATION_CHANGED: 'Navigation changed',
  ONSITE_NOTIF_OPENED: 'Onsite notification opened',
  SINGLE_DO_CLICKED: 'Single DO clicked',
  SINGLE_DO_SUBMITTED: 'Single DO Submitted'
};
export const TON_TO_KG_MULTIPLIER = 1000;

export const PRODUCT_CATEGORY = {
  ALCOHOLIC_BEVARAGES: 'ALCOHOLIC_BEVARAGES',
  DRINKING_WATER: 'DRINKING_WATER',
  COFFEE: 'COFFEE',
  FRUIT_FLAVORED_DRINKS: 'FRUIT_FLAVORED_DRINKS',
  JUICE: 'JUICE',
  MILK: 'MILK',
  NON_DAIRY: 'NON_DAIRY',
  POWDERED_BEVERAGES_MIXES: 'POWDERED_BEVERAGES_MIXES',
  SODA: 'SODA',
  SPORTS_ENERGY_DRINKS: 'SPORTS_ENERGY_DRINKS',
  TEA_INFUSIONS: 'TEA_INFUSIONS',
  WATER: 'WATER',
  BAKERY: 'BAKERY',
  SNAKES: 'SNAKES',
  CONDIMENTS_SOUCES: 'CONDIMENTS_SOUCES',
  COOKING_BAKING_INGREDIENTS: 'COOKING_BAKING_INGREDIENTS',
  DAIRY: 'DAIRY',
  DIPS: 'DIPS',
  FOOD: 'FOOD',
  FROZEN: 'FROZEN',
  FRUITS: 'FRUITS',
  GRAINS: 'GRAINS',
  MEAT: 'MEAT',
  NUTS: 'NUTS',
  PASTA: 'PASTA',
  PREPARED: 'PREPARED',
  SPICES: 'SPICES',
  SNACKS: 'SNACKS',
  SOUPS: 'SOUPS',
  TOUFU: 'TOUFU',
  FITNESS_AND_NUTRITION: 'FITNESS_AND_NUTRITION',
  MEDICINE_AND_DRUGS: 'MEDICINE_AND_DRUGS',
  FMCG: 'FMCG',
  PLASTIC: 'PLASTIC',
  PACKAGING: 'PACKAGING'
};

export const SHIPMENT_TYPE = {
  MT: 'MT',
  GT: 'GT',
  W2W: 'W2W',
  F2DEPO: 'F2DEPO',
  F2DIST: 'F2DIST',
  F2MT: 'F2MT',
  F2W: 'F2W',
  W2DEPO: 'W2DEPO',
  W2DIST: 'W2DIST',
  W2MT: 'W2MT'
};
export const PANTHERA_PIC_DIVISION = {
  SALES: 'SALES',
  SALES_SUPPORT: 'SALES_SUPPORT',
  ACCOUNT_RECEIVABLE: 'ACCOUNT_RECEIVABLE',
  CANVASSER: 'CANVASSER'
};
export const USER_SALES_ROLE = {
  SME_TELESALES: 'SME_TELESALES',
  SME_SALES_EXECUTIVE: 'SME_SALES_EXECUTIVE',
  SME_TEAM_LEADER: 'SME_TEAM_LEADER'
};
export const BROKERAGE_EXTRA_USER_ACCESS_TYPE = {
  ACCOUNT_PAYABLE: 'ACCOUNT_PAYABLE',
  AR: 'AR',
  DOCUMENT_COLLECTION: 'DOCUMENT_COLLECTION',
  FINANCE: 'FINANCE',
  FINANCE_ADMIN: 'FINANCE_ADMIN',
  SALES: 'SALES',
  SALES_SUPPORT: 'SALES_SUPPORT',
  TRAFFIC_CONTROLLER: 'TRAFFIC_CONTROLLER',
  TRANSPORT_PLANNER: 'TRANSPORT_PLANNER',
  VENDOR_MANAGEMENT: 'VENDOR_MANAGEMENT',
  KARGO_SHIPPER_SALES: 'KARGO_SHIPPER_SALES',
};

export const GOODS_REJECTION_CATEGORY = {
  CLAIM: 'CLAIM',
  NO_CLAIM: 'NO_CLAIM',
  REPACKING: 'REPACKING'
};

export const SHOW_CLAIMS_IN = {
  POD: 'POD',
  BERITA_ACARA: 'BERITA_ACARA',
  LOAD_ITEMS: 'LOAD_ITEMS'
};

export default {};
