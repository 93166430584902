import React, { useMemo, Suspense, useEffect, lazy, useContext } from 'react';
import {
  Switch,
  Redirect,
  Route,
  useLocation
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash-es';

import { PageSuspenseFallback } from '@kargotech/tms-ui/atoms';
import {
  LOCAL_SHIPMENT_STATUSES,
  SHIPMENT_COMPANY_CONSTANT_TYPES,
  CUSTOM_CITY_CONSTANT_NAME,
  CUSTOM_REGION_CONSTANT_NAME,
  CUSTOM_LOCATION_GROUP_NAME,
  CUSTOM_PRODUCT_GROUP_NAME,
  CUSTOM_QUANTITY_TYPE_NAME,
  CUSTOM_CITY_REGION_MAPPING_METADATA,
  FIRST_MILE_COMPANY_CONSTANT_TYPES, // First Mile Project Specific
  S_TMS_TRACKER_EVENTS
} from '~/Configurations/constants';
import { PrivateRoute, AuthRoute } from '../Components/Route';
import { ProfileContext } from '../Contexts/ProfileProvider';
import { ACCESS_PRIVILEGE } from './accessPrevillegeMap';

import tracker from '~/Services/tracker';

/* eslint-disable max-len */
const MainLayout = lazy(() => import(/* webpackChunkName: "MainLayout" */ '~/Layouts/MainLayout'));
const MinimalLayout = lazy(() => import(/* webpackChunkName: "MinimalLayout" */ '~/Layouts/MinimalLayout'));

const LoginPage = lazy(() => import(/* webpackChunkName: "LoginPage" */ '~/Pages/Auth/Login'));
const RegisterPage = lazy(() => import(/* webpackChunkName: "RegisterPage" */ '~/Pages/Auth/Register'));
const RegisterDetailsPage = lazy(() => import(/* webpackChunkName: "RegisterDetailsPage" */ '~/Pages/Auth/Register/RegisterDetails'));
const OtpVerification = lazy(() => import(/* webpackChunkName: "OtpVerification" */ '~/Pages/Auth/OtpVerification'));
const ChooseCompanyPage = lazy(() => import(/* webpackChunkName: "ChooseCompanyPage" */ '~/Pages/Auth/ChooseCompanyPage'));

const CompanyDetails = lazy(() => import(/* webpackChunkName: "CompanyDetails" */ '~/Pages/Company/CompanyDetails'));
const DOTemplates = lazy(() => import(/* webpackChunkName: "DOTemplates" */ '~/Pages/Company/DOTemplates'));

const Employees = lazy(() => import(/* webpackChunkName: "Employees" */ '~/Pages/Company/Employees'));
const CompanyProducts = lazy(() => import(/* webpackChunkName: "CompanyProducts" */ '~/Pages/Company/CompanyProducts'));
const CompanyBiddingPriceSettings = lazy(() => import(/* webpackChunkName: "CompanyBiddingPriceSettings" */ '~/Pages/Company/CompanyBiddingPriceSettings'));
const PersonalAccount = lazy(() => import(/* webpackChunkName: "PersonalAccount" */ '~/Pages/PersonalAccount'));
const TransporterListPage = lazy(() => import(/* webpackChunkName: "TransporterListPage" */ '~/Pages/Company/Transporter/TransporterListPage'));
const TransporterDetailsPage = lazy(() => import(/* webpackChunkName: "TransporterDetailsPage" */ '~/Pages/Company/Transporter/TransporterDetailsPage'));
const VendorContract = lazy(() => import(/* webpackChunkName: "VendorContract" */ '~/Pages/Company/VendorContract'));

const LocationListPage = lazy(() => import(/* webpackChunkName: "LocationListPage" */ '~/Pages/Company/Location/LocationListPage'));
const LocationDetailsPage = lazy(() => import(/* webpackChunkName: "LocationDetailsPage" */ '~/Pages/Company/Location/LocationDetailsPage'));

const ShipmentListPage = lazy(() => import(/* webpackChunkName: "ShipmentListPage" */ '~/Pages/Shipment/ShipmentListPage'));
const ShipmentDetailPage = lazy(() => import(/* webpackChunkName: "ShipmentDetailPage" */ '~/Pages/Shipment/ShipmentDetailPage'));
const ContainerDetailPage = lazy(() => import(/* webpackChunkName: "ContainerDetailPage" */ '~/Pages/Container/ContainerDetailPage'));

const Container = lazy(() => import(/* webpackChunkName: "Container" */ '~/Pages/Container/ContainerListPage'));
const FirstMileInvoiceListPage = lazy(() => import(/* webpackChunkName: "FirstMileInvoiceListPage" */ '~/Pages/FirstMileInvoice/FirstMileInvoiceListPage'));
const FirstMileInvoiceDetailPage = lazy(() => import(/* webpackChunkName: "FirstMileInvoiceDetailPage" */ '~/Pages/FirstMileInvoice/FirstMileInvoiceDetailPage'));
const FirstMileInvoiceReport = lazy(() => import(/* webpackChunkName: "FirstMileInvoiceReport" */ '~/Pages/FirstMileInvoice/FirstMileInvoiceListPage/FirstMileInvoiceReport'));

// -- [Start] First Mile Project Specific
const BillOfLadingListPage = lazy(() => import(/* webpackChunkName: "BillOfLadingListPage" */ '~/Pages/BillOfLading/BillOfLadingListPage'));
const BillOfLadingDetailPage = lazy(() => import(/* webpackChunkName: "BillOfLadingDetailPage" */ '~/Pages/BillOfLading/BillOfLadingDetailPage'));
const BillOfLoadingScheduleOverview = lazy(() => import(/* webpackChunkName: "BillOfLoadingScheduleOverview" */ '~/Pages/BillOfLading/ScheduleOverview'));
const CompanyConstantPage = lazy(() => import(/* webpackChunkName: "CompanyConstantPage" */ '~/Pages/Customers/CompanyConstantPage'));
const ContainerVolumeReport = lazy(() => import(/* webpackChunkName: "ContainerVolumeReport" */ '~/Pages/Report/ContainerReport/VolumeReport'));
const ContainerScheduleReport = lazy(() => import(/* webpackChunkName: "ContainerScheduleReport" */ '~/Pages/Report/ContainerReport/ScheduleReport'));
// -- [End] First Mile Project Specific

const DeliveryListPage = lazy(() => import(/* webpackChunkName: "DeliveryListPage" */ '~/Pages/Delivery/DeliveryListPage'));

const DeliveryDetailPage = lazy(() => import(/* webpackChunkName: "DeliveryDetailPage" */ '~/Pages/Delivery/DeliveryDetailPage'));
const OrderVehicleMonitoringPage = lazy(() => import(/* webpackChunkName: "OrderVehicleMonitoringPage" */ '~/Pages/Vehicle/OrderVehicleMonitoringPage'));
const TransitTimeReport = lazy(() => import(/* webpackChunkName: "TransitTimeReport" */ '~/Pages/Insights/TransitTimeReport'));
const ShipmentPerformanceReport = lazy(() => import(/* webpackChunkName: "ShipmentPerformanceReport" */ '~/Pages/Insights/ShipmentPerformanceReport'));
const VendorReport = lazy(() => import(/* webpackChunkName: "VendorReport" */ '~/Pages/Insights/VendorReport'));
const WelcomePage = lazy(() => import(/* webpackChunkName: "WelcomePage" */ '~/Pages/WelcomePage'));

const SetBidWinnerRedirect = lazy(() => import('~/Pages/Payment/Components/SetDOBiddingDataForm/SetBidWinnerRedirect'));

const NotFound = lazy(() => import(/* webpackChunkName: "NotFound" */ '../Pages/App/NotFound'));
/* eslint-enable max-len */

const firstMileCompanyConstants = Object.keys(FIRST_MILE_COMPANY_CONSTANT_TYPES);
const shipmentCompanyConstants = Object.keys(SHIPMENT_COMPANY_CONSTANT_TYPES);

const ShipperRoutes = () => {
  const { t } = useTranslation();

  const {
    profile,
    isAuthorizedToAccess,
    isFirstMileShipper,
    getCompanyMetadata
  } = useContext(ProfileContext);

  const location = useLocation();

  const hasCustomCityRegionMapping = useMemo(() => {
    const metadata = getCompanyMetadata() || {};
    return metadata[CUSTOM_CITY_REGION_MAPPING_METADATA];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCompanyMetadata, profile]);

  useEffect(
    () => {
      const { pathname: path } = location;
      tracker.event(S_TMS_TRACKER_EVENTS.NAVIGATION_CHANGED, {
        path
      });
    },
    [location]
  );

  const companyConstantTabNavigation = useMemo(() => [
    ...shipmentCompanyConstants,
    ...(hasCustomCityRegionMapping ? [
      CUSTOM_CITY_CONSTANT_NAME,
      CUSTOM_REGION_CONSTANT_NAME,
      CUSTOM_LOCATION_GROUP_NAME,
      CUSTOM_QUANTITY_TYPE_NAME,
      CUSTOM_PRODUCT_GROUP_NAME
    ] : []),
    ...(isFirstMileShipper && isAuthorizedToAccess([
      ...ACCESS_PRIVILEGE.FIRST_MILE_COMPANY_CONSTANT_READ,
      ...ACCESS_PRIVILEGE.FIRST_MILE_COMPANY_CONSTANT_WRITE
    ]) ? firstMileCompanyConstants : [])
  ].map(constantName => ({
    name: constantName,
    path: `/company/${constantName.toLowerCase().replace('_', '-')}`,
    title: t(`common:${constantName.toLowerCase()}`)
  })), [hasCustomCityRegionMapping, isAuthorizedToAccess, isFirstMileShipper, t]);

  // -- [Start] First Mile Project Specific
  const deliveryPageTabNavigation = Object.keys(LOCAL_SHIPMENT_STATUSES)
    .filter(statusKey => {
      if (LOCAL_SHIPMENT_STATUSES[statusKey] === LOCAL_SHIPMENT_STATUSES.UPCOMING) {
        return false;
      }

      if (
        [
          LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE,
          LOCAL_SHIPMENT_STATUSES.IN_TRANSIT
        ].includes(LOCAL_SHIPMENT_STATUSES[statusKey])
      ) {
        return !isFirstMileShipper;
      }

      return true;
    })
    .map(
      statusKey => ({
        path: `/delivery-order/list/${LOCAL_SHIPMENT_STATUSES[statusKey]}`,
        title: t(
          `common:local_shipment_statuses.${LOCAL_SHIPMENT_STATUSES[statusKey]}`
        )
      })
    );

  const containerPageNavigation = [
    {
      path: '/container/all',
      title: t('first_mile:container_all')
    },
    {
      path: '/container/in-holding',
      title: t('first_mile:container_in_holding')
    }
  ];
  // -- [End] First Mile Project Specific

  const companyPageTabNavigation = [
    {
      path: '/company/details',
      title: t('common:company')
    },
    {
      path: '/company/do-templates',
      title: t('common:routine_order')
    }
  ];

  const containerReportsTabNavigation = [
    {
      path: '/reports/schedule',
      title: t('first_mile:schedule_report')
    },
    {
      path: '/reports/volume',
      title: t('first_mile:volume_report')
    }
  ].filter(Boolean);

  const firstMileInvoiceTabNavigation = [
    {
      path: '/first-mile-invoice/list',
      title: t('common:list')
    },
    {
      path: '/first-mile-invoice/download',
      title: t('common:download')
    }
  ].filter(Boolean);

  const shipmentPageTabNavigation = Object.keys(LOCAL_SHIPMENT_STATUSES)
    .filter(statusKey => [
      LOCAL_SHIPMENT_STATUSES.PLANNED,
      LOCAL_SHIPMENT_STATUSES.ONGOING,
      LOCAL_SHIPMENT_STATUSES.HISTORY,
    ].includes(LOCAL_SHIPMENT_STATUSES[statusKey]))
    .map(
      statusKey => ({
        path: `/shipment/list/${LOCAL_SHIPMENT_STATUSES[statusKey]}`,
        title: t(
          `common:local_shipment_statuses.${LOCAL_SHIPMENT_STATUSES[statusKey]}`
        )
      })
    );

  const insightsPageNavigation = [
    {
      path: '/insights/transit-time',
      title: t('common:transit_time')
    },
    {
      path: '/insights/shipment-performance',
      title: t('common:shipments')
    },
    {
      path: '/insights/vendor-performance',
      title: t('common:vendors')
    }
  ];

  const renderDefaultRootPath = () => {
    if (isFirstMileShipper) {
      return <Redirect exact={true} from="/" to="/bill-of-lading" />;
    }

    return <Redirect exact={true} from="/" to="/shipment" />;
  };

  return (
    <Suspense fallback={<PageSuspenseFallback />}>
      <Switch>
        <Route path="/auth">
          <MinimalLayout>
            <Suspense fallback={<PageSuspenseFallback />}>
              <Switch>
                <AuthRoute
                  path="/auth/login/:accessType?"
                  exact={true}
                  title={t('common:login')}
                  showLogo={true}
                >
                  <LoginPage />
                </AuthRoute>
                <AuthRoute
                  path="/auth/register"
                  exact={true}
                  title={t('common:register')}
                  showLogo={true}
                >
                  <RegisterPage />
                </AuthRoute>
                <AuthRoute
                  path="/auth/register/details/:phoneNumber"
                  exact={true}
                  title={t('message:fill_account_details')}
                  showLogo={false}
                >
                  <RegisterDetailsPage />
                </AuthRoute>
                <AuthRoute
                  path="/auth/verification/:phoneNumber"
                  exact={true}
                >
                  <OtpVerification options="REGISTRATION" />
                </AuthRoute>
                <AuthRoute
                  path="/auth/first-time-login/verification/:phoneNumber"
                  exact={true}
                >
                  <OtpVerification options="FIRST_TIME_LOGIN" />
                </AuthRoute>
                <AuthRoute
                  path="/auth/forgot-password/:phoneNumber"
                  exact={true}
                >
                  <OtpVerification options="RESET_PASSWORD" />
                </AuthRoute>
                <AuthRoute
                  title={t('common:choose_company')}
                  showLogo={false}
                  path="/auth/choose-company"
                  exact={true}
                >
                  <ChooseCompanyPage />
                </AuthRoute>
                <Redirect to="/auth/login" />
              </Switch>
            </Suspense>
          </MinimalLayout>
        </Route>

        <PrivateRoute path="/welcome" title="">
          <MinimalLayout>
            <WelcomePage />
          </MinimalLayout>
        </PrivateRoute>

        <PrivateRoute path="/">
          <MainLayout>
            <Suspense fallback={<PageSuspenseFallback />}>
              <Switch>
                <PrivateRoute
                  title={t('common:personal_account')}
                  path="/personal-account"
                  exact={true}
                >
                  <PersonalAccount />
                </PrivateRoute>

                <PrivateRoute
                  path="/company/details"
                  exact={true}
                  title={t('common:company_settings')}
                  tabNavigation={companyPageTabNavigation}
                >
                  <CompanyDetails />
                </PrivateRoute>

                <PrivateRoute
                  path="/company/do-templates"
                  exact={true}
                  title={t('common:company_settings')}
                  tabNavigation={companyPageTabNavigation}
                >
                  <DOTemplates />
                </PrivateRoute>

                <PrivateRoute
                  path="/company/employees"
                  exact={true}
                  title={t('common:company_settings')}
                  tabNavigation={companyPageTabNavigation}
                >
                  <Employees />
                </PrivateRoute>

                <PrivateRoute
                  path="/company/company-products"
                  exact={true}
                  title={t('common:sku')}
                  tabNavigation={companyPageTabNavigation}
                >
                  <CompanyProducts />
                </PrivateRoute>

                <PrivateRoute
                  path="/company/pricing-settings"
                  exact={true}
                  title={t('common:pricing_settings')}
                  tabNavigation={companyPageTabNavigation}
                >
                  <CompanyBiddingPriceSettings />
                </PrivateRoute>

                <PrivateRoute
                  path="/set-bid-price/:deliveryOrderKsuid"
                  exact={true}
                >
                  <SetBidWinnerRedirect />
                </PrivateRoute>

                <PrivateRoute
                  path="/company/vendor/details/:vendorKsuid"
                  exact={true}
                  skipTabNavigationSetup={true}
                >
                  <TransporterDetailsPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/company/vendor/contracts/:vendorKsuid"
                  exact={true}
                  skipTabNavigationSetup={true}
                >
                  <VendorContract />
                </PrivateRoute>
                <Redirect
                  exact={true}
                  from="/company"
                  to={get(companyPageTabNavigation, '[0].path')}
                />

                <PrivateRoute
                  path="/company/vendors"
                  exact={true}
                  title={t('common:vendors')}
                >
                  <TransporterListPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/company/locations"
                  exact={true}
                  title={t('common:location_and_warehouse')}
                >
                  <LocationListPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/company/locations/:locationId"
                  exact={true}
                >
                  <LocationDetailsPage />
                </PrivateRoute>

                <PrivateRoute
                  path="/tracker"
                  exact={true}
                  title={t('common:truck_monitoring')}
                >
                  <OrderVehicleMonitoringPage />
                </PrivateRoute>

                <PrivateRoute
                  path="/shipment/list/:shipmentStatus"
                  exact={true}
                  title={t('common:order', { count: 2 })}
                  tabNavigation={shipmentPageTabNavigation}
                >
                  <ShipmentListPage />
                </PrivateRoute>

                <Redirect
                  exact={true}
                  from="/shipment"
                  to="/shipment/list/planned"
                />

                <Redirect
                  exact={true}
                  from="/shipment/list"
                  to="/shipment/list/planned"
                />

                <PrivateRoute
                  path="/container/details/:containerID"
                  exact={true}
                >
                  <ContainerDetailPage />
                </PrivateRoute>

                <PrivateRoute
                  path="/container/:status?"
                  exact={true}
                  title={t('common:container')}
                  tabNavigation={containerPageNavigation}
                >
                  <Container />
                </PrivateRoute>

                <Redirect
                  exact={true}
                  from="/container"
                  to="/container/all"
                />

                <PrivateRoute
                  path="/first-mile-invoice/details/:invoiceID"
                  exact={true}
                >
                  <FirstMileInvoiceDetailPage />
                </PrivateRoute>

                <PrivateRoute
                  path="/first-mile-invoice/list"
                  exact={true}
                  title={t('common:invoice')}
                  tabNavigation={firstMileInvoiceTabNavigation}
                >
                  <FirstMileInvoiceListPage />
                </PrivateRoute>

                <PrivateRoute
                  path="/first-mile-invoice/download"
                  exact={true}
                  tabNavigation={firstMileInvoiceTabNavigation}
                  title={t('common:download')}
                >
                  <FirstMileInvoiceReport />
                </PrivateRoute>

                <Redirect
                  exact={true}
                  from="/first-mile-invoice"
                  to="/first-mile-invoice/list"
                />

                <PrivateRoute
                  path="/shipment/details/:shipmentID"
                  exact={true}
                >
                  <ShipmentDetailPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/shipment/details/:shipmentID"
                  exact={true}
                >
                  <ShipmentDetailPage />
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  path="/insights/transit-time"
                  tabNavigation={insightsPageNavigation}
                  title={t('common:insights')}
                >
                  <TransitTimeReport />
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  path="/insights/shipment-performance"
                  tabNavigation={insightsPageNavigation}
                  title={t('common:insights')}
                >
                  <ShipmentPerformanceReport />
                </PrivateRoute>

                <PrivateRoute
                  exact={true}
                  path="/insights/vendor-performance"
                  tabNavigation={insightsPageNavigation}
                  title={t('common:insights')}
                >
                  <VendorReport />
                </PrivateRoute>

                <Redirect
                  exact={true}
                  from="/insights"
                  to="/insights/transit-time"
                />

                { /* [Start] First Mile Project Specific */}
                <PrivateRoute
                  path="/delivery-order/list/:deliveryStatus"
                  exact={true}
                  title={t('common:delivery_order')}
                  tabNavigation={deliveryPageTabNavigation}
                >
                  <DeliveryListPage />
                </PrivateRoute>
                <PrivateRoute
                  path="/delivery-order/details/:deliveryID"
                  exact={true}
                >
                  <DeliveryDetailPage />
                </PrivateRoute>
                <Redirect
                  exact={true}
                  from="/delivery-order"
                  to="/delivery-order/list/"
                />
                <Redirect
                  exact={true}
                  from="/delivery-order/list"
                  to="/delivery-order/list/planned"
                />

                <PrivateRoute
                  path="/bill-of-lading/overview"
                  exact={true}
                  title={t('first_mile:dashboard')}
                >
                  <BillOfLoadingScheduleOverview />
                </PrivateRoute>

                <PrivateRoute
                  path="/bill-of-lading/list"
                  exact={true}
                  title={t('first_mile:bill_of_lading')}
                >
                  <BillOfLadingListPage />
                </PrivateRoute>

                <Redirect
                  exact={true}
                  from="/bill-of-lading"
                  to="/bill-of-lading/overview"
                />

                <PrivateRoute
                  path="/bill-of-lading/details/:blKsuid"
                  exact={true}
                >
                  <BillOfLadingDetailPage />
                </PrivateRoute>

                <PrivateRoute
                  path="/reports/schedule"
                  exact={true}
                  title={t('first_mile:schedule_report')}
                  tabNavigation={containerReportsTabNavigation}
                >
                  <ContainerScheduleReport />
                </PrivateRoute>

                <PrivateRoute
                  path="/reports/volume"
                  exact={true}
                  title={t('first_mile:volume_report')}
                  tabNavigation={containerReportsTabNavigation}
                >
                  <ContainerVolumeReport />
                </PrivateRoute>

                <Redirect
                  exact={true}
                  from="/reports"
                  to="/reports/schedule"
                />

                {
                companyConstantTabNavigation.map(constantNav => (
                  <PrivateRoute
                    key={constantNav.path}
                    path={constantNav.path}
                    exact={true}
                    title={constantNav.title}
                    tabNavigation={companyPageTabNavigation}
                  >
                    <CompanyConstantPage type={constantNav.name} />
                  </PrivateRoute>
                ))
              }
                { /* [End] First Mile Project Specific */}

                {renderDefaultRootPath()}
                <Route component={NotFound} />
              </Switch>
            </Suspense>
          </MainLayout>
        </PrivateRoute>

        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default ShipperRoutes;
