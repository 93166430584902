import { SHIPMENT_STATUSES, SHOW_CLAIMS_IN } from '~/Configurations/constants';

/* eslint-disable max-len */
export default {
  account_deactivation_warning_message: 'Anda tidak akan bisa gunakan rekening bank ini untuk membuat invoice',
  account_successfully_updated_as_default: 'Rekening {{account_number}} berhasil disimpan sebagai rekening utama',
  activity_log_time: 'Aktivitas: {{time}} {{timezone}}',
  cancel_do: 'Batalkan {{do_number}}?',
  change_shipment_status: 'Ubah status {{do_number}}?',
  change_status_time: 'Ubah waktu status {{do_number}}?',
  customer_successfully_created: '{{name}} berhasil ditambahkan.',
  customer_successfully_updated: '{{name}} berhasil diubah.',
  date_range_invalid: 'Rentang tanggal {{field_start}} dan {{field_end}} tidak valid',
  delete_bill_of_lading: 'Hapus {{blNumber}}?',
  delete_this_bank_account: 'Hapus rekening bank ini?',
  detail_of: 'Detail {{object}}',
  document_uploaded_successfully: 'Dokumen berhasil diunggah',
  file_error: {
    1: 'Perusahaan tidak ditemukan',
    9: 'Gudang tidak ditemukan',
    13: 'Lokasi alamat tidak valid',
    45: 'Anda sudah punya Biaya Lokasi dengan Tipe Biaya, Tipe Pengiriman, Tipe Truk, dan Subtipe truk yang sama',
    46: 'Anda sudah punya Biaya Lokasi dengan Tipe Biaya, Tipe Pengiriman, Tipe Truk, dan Subtipe truk yang sama',
    55: 'ID jenis kendaraan tidak valid',
    60: 'Alamat ({{column_name}}) tidak ditemukan',
    61: 'Alamat ({{column_name}}) berlokasi di luar Indonesia',
    62: 'Tanggal muat tidak valid',
    63: 'Tanggal bongkar tidak valid',
    64: 'Armada tidak ditemukan',
    65: 'Jenis barang tidak valid',
    66: 'Customer tidak ditemukan',
    67: 'Supir tidak ditemukan',
    96: 'BL not found',
    97: 'Invalid container status',
    98: 'Invalid container type',
    99: 'Warehouse not found',
    474: 'Header file CSV tidak valid',
    475: 'Kolom {{column_name}} tidak valid',
    476: 'File CSV tidak valid',
    477: 'Header file CSV kosong',
    478: 'Kesalahan yang tidak diketahui',
    479: 'Urutan pelolosan yang tidak valid ditemukan dalam satu baris',
    480: 'Panjang kolom tidak valid untuk satu baris',
    481: 'Baris memiliki kutipan yang menyimpang',
    483: 'Nomor Lisensi Kendaraan sudah terdaftar di perusahaan',
    2501: 'Location ID tidak ditemukan',
    2502: 'Fee type tidak valid',
    2503: 'Shipment type tidak valid',
    2504: 'Max amount tidak valid',
    2505: 'Reimbursement percentage tidak valid',
    2506: 'Reimbursement requirement tidak valid',
    2507: 'Truck type/subtype tidak ditemukan',
    2508: 'Reimbursement type tidak valid'
  },
  form_dialog: {
    fill_in_this_CSV_file_to_upload_a_entity: 'Isi file CSV ini untuk menunggah {{entity}}.',
    upload_entity_from_csv_file: 'Unggah {{entity}} dari file CSV'
  },
  form_error_message: {
    choose_category: 'Pilih {{field}}',
    document_is_required: 'This document has to be uploaded.',
    invalid_format: 'Masukkan {{field}} yang valid',
    mandatory: 'Anda wajib melengkapi {{field}}',
    max_length: 'Maksimum panjang {{field}} adalah {{max}}',
    max_value: '{{field}} harus kurang dari {{max}}',
    min_length: 'Minimum panjang {{field}} adalah {{min}}',
    percentage_length: 'Total Persentase harus 100%',
    required: 'Masukkan {{field}}',
    strong_password_rule: '{{field}} setidaknya harus memiliki satu karakter huruf besar, huruf kecil, angka, dan karakter spesial'
  },
  general_add: 'Tambah {{name}}',
  general_choose: 'Pilih {{name}}',
  general_confirmation: 'Konfirmasi {{name}}',
  general_create: 'Buat {{name}}',
  general_creation_success_message: '{{name}} berhasil ditambahkan.',
  general_deactivated: '{{name}} deactivated',
  general_deactivation_confirmation: 'Nonaktifkan {{name}}?',
  general_deactivation_success_message: '{{name}} berhasil dinonaktifkan',
  general_deactivation_warning_message: 'Anda yakin ingin menonaktifkan {{name}}',
  general_edit: 'Ubah {{name}}',
  general_edit_detail: 'Ubah Detail {{name}}',
  general_edit_success_message: '{{name}} berhasil diubah.',
  general_list: 'Daftar {{name}}',
  general_new_item: '{{name}} Baru',
  general_removal_confirmation: 'Hapus {{name}}?',
  general_removal_success_message: '{{name}} berhasil dihapus',
  general_removal_warning_message: 'Anda yakin ingin menghapus {{name}}?',
  general_remove: 'Hapus {{name}}',
  general_request: 'Permintaan {{name}}',
  general_search: 'Cari {{name}}',
  general_see: 'Lihat {{object}}',
  general_submit_success_message: '{{name}} berhasil terkirim.',
  general_successfully_saved: '{{name}} berhasil disimpan',
  general_summary: 'Ringkasan {{name}}',
  generic_text_input_placeholder: 'Ketik {{field}}',
  KTP_uploaded_successfully: 'KTP berhasil diunggah',
  minutes: '{{minutes}} menits',
  NPWP_uploaded_successfully: 'NPWP berhasil diunggah',
  pagination_indicator: 'Halaman {{page}} dari {{total}}',
  pic_sequence: 'PIC {{sequence}}',
  resend_otp_countdown_message: 'Kirim ulang kode dalam {{countdown}}',
  shipment_status_manual_update_log: 'Status diubah menjadi {{updated_status}} oleh {{actor}}',
  shipment_created_at: 'Pengiriman Dibuat: {{created_at}}',
  shipment_status_tracker_map: {
    [SHIPMENT_STATUSES.UPCOMING]: 'Pengiriman dibuat oleh {{actor}}',
    [SHIPMENT_STATUSES.PLANNED]: 'Truk Belum Dipilih',
    [SHIPMENT_STATUSES.ASSIGNED]: 'Truk & supir dipilih',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'Dalam perjalanan ke lokasi muat',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'Sampai di lokasi muat',
    [SHIPMENT_STATUSES.START_LOADING]: 'Mulai muat',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Selesai muat',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'Dalam perjalanan ke lokasi bongkar',
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'Tiba di lokasi bongkar',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Mulai bongkar',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Selesai bongkar',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Pengiriman selesai',
    [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Dibatalkan',
    [SHIPMENT_STATUSES.UNFULFILLED]: 'Tidak Terpenuhi',
    [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'Customer Mengalihkan Penugasan',
    [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'Tidak Terpenuhi',
    [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'Waktu Konfirmasi Habis',
    [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'Customer Membatalkan, alasan: {{inactive_reason}}'
  },
  shipper_notes_claims: {
    cond_e: 'Untuk transporter dengan asuransi, driver harus memiliki bukti klaim seperti foto bongkar dan muat untuk dokumentasi. Jika bukti tidak mencukupi, klaim akan dibebankan sepenuhnya ke pihak transporter. Akan ada form tersendiri untuk menginput bukti-bukti ini.',
    cond_a: 'Apabila ada barang muatan yang {{damageType}} maka barang harus dikembalikan ke pihak pengirim.\nHarap menghubungi pihak Kargo di WhatsApp +6281213116433 (hanya melalui chat, tidak bisa melalui telepon) untuk tujuan pengembalian barang (pitstop terdekat/gudang bongkar/muat) dan konfirmasi jenis penolakan yang lain.',
    cond_b: 'Apabila ada barang muatan yang ditolak karena {{damageType}} maka klaim tersebut menjadi tanggung jawab anda.\nHarap menghubungi pihak Kargo di WhatsApp +6281213116433 (hanya melalui chat, tidak bisa melalui telepon) jika ada pertanyaan.',
    cond_c: 'Pengembalian barang / penyelesaian klaim di tempat dapat mengurangi jumlah total klaim bila dapat di gunakan ulang oleh shipper. Hal ini akan sepenuhnya diputuskan oleh shipper.',
    cond_d: 'Pastikan driver mendapatkan keterangan klaim lengkap di {{showClaimsIn}}. Tanda tangan driver di dokumen tersebut menandakan bahwa driver menyetujui keterangan klaim tersebut.'
  },
  show_claims_in_map: {
    [SHOW_CLAIMS_IN.POD]: 'POD',
    [SHOW_CLAIMS_IN.BERITA_ACARA]: 'Berita Acara',
    [SHOW_CLAIMS_IN.LOAD_ITEMS]: 'Load Items'
  },
  SPPKP_uploaded_successfully: 'SPPKP berhasil diunggah',
  upload_the_KTP_with_a_maximum_size: 'Unggah KTP dengan ukuran maksimal {{size}}.',
  upload_the_NPWP_with_a_maximum_size: 'Unggah NPWP dengan ukuran maksimal {{size}}.',
  upload_the_SPPKP_with_a_maximum_size: 'Unggah SPPKP dengan ukuran maksimal {{size}}.',
  uploaded_file_medium_subtitle: 'Diunggah pada {{datetime}} dari {{source}}',
  uploaded_file_short_subtitle: 'Diunggah pada {{datetime}}',
  user_deactivation_confirmation: 'Nonaktifkan {{name}}?',
  user_deactivation_success_message: '{{name}} berhasil dinonaktifkan',
  user_deactivation_warning_message: '{{name}} tidak akan dapat mengakses Vendor Central, namun informasinya akan tetap disimpan. Anda tidak dapat mengaktifkannya kembali.',
  user_successfully_created: '{{name}} berhasil ditambahkan.',
  user_successfully_updated: '{{name}} berhasil diubah.',
  warehouse_successfully_created: '{{name}} berhasil ditambahkan.',
  warehouse_successfully_updated: '{{name}} berhasil diubah.',
  warehouse_successfully_uploaded: '{{name}} berhasil diunggah',
  x_container_selected: '{{total_selected}} container terpilih',
};
