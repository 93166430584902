import React, { useCallback, useMemo } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { get } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { TmsUIProvider } from '@kargotech/tms-ui';
import { AuthProvider } from '@kargotech/tms-core/auth';
import { APP_CONTEXT_STORAGE_KEY, S_TMS_TRACKER_EVENTS } from '~/Configurations/constants';

import theme from './Configurations/theme';
import Routes from './Configurations/Routes';
import AppProvider from './Contexts/AppProvider';
import ApolloClientProvider from './Contexts/ApolloClientProvider';
import ProfileProvider from './Contexts/ProfileProvider';
import NavigationProvider from './Contexts/NavigationProvider';
import { APOLLO_CLIENTS } from './Services/apollo';
import tracker from './Services/tracker';

require('dotenv').config();

export default function App() {
  const { i18n } = useTranslation();

  const handleLogout = useCallback(() => {
    Object.keys(APOLLO_CLIENTS).forEach(apolloClient => {
      const cache = get(APOLLO_CLIENTS, `${apolloClient}.cache`);
      if (cache) {
        cache.reset();
      }
    });
  }, []);

  const commonAuthTrackerEvents = useMemo(() => ({
    onLoginSuccess() {
      tracker.event(S_TMS_TRACKER_EVENTS.LOGIN);
    }
  }), []);

  return (
    <AppProvider>
      <TmsUIProvider i18n={i18n}>
        <AuthProvider
          apiClient={APOLLO_CLIENTS.PROFILE}
          onLogoutSuccess={handleLogout}
          preservedLocalStorageKeys={[APP_CONTEXT_STORAGE_KEY]}
          {...commonAuthTrackerEvents}
        >
          <ThemeProvider theme={theme}>
            <ApolloClientProvider>
              <ProfileProvider>
                <NavigationProvider>
                  <Router>
                    <Routes />
                  </Router>
                </NavigationProvider>
              </ProfileProvider>
            </ApolloClientProvider>
          </ThemeProvider>
        </AuthProvider>
      </TmsUIProvider>
    </AppProvider>
  );
}
