/* eslint-disable max-len */
import { DELIVERY_ORDER_WAREHOUSE_FILTER_TYPE, DERIVED_SHIPMENT_STATUSES, FIRST_MILE_USER_ACCESS_TYPE, LOCAL_SHIPMENT_STATUSES, SHIPMENT_STATUSES, SHIPPER_LOCATION_OPERATING_DAY, USER_ACCESS_TYPE, COMPANY_STATUSES, PANTHERA_PIC_DIVISION, PRODUCT_CATEGORY, USER_SALES_ROLE, USER_STATUSES, CUSTOMER_DIVISION, BROKERAGE_EXTRA_USER_ACCESS_TYPE } from '~/Configurations/constants';

export default {
  '24_hrs': '24 jam',
  acceptance_date: 'Tanggal Penerimaan',
  account_details: 'Detail Akun',
  account_holder_name: 'Nama Pemegang Rekening',
  account_name: 'Nama Akun',
  account_number: 'Nomor Rekening',
  active: 'Aktif',
  activity_log_type: {
    comment: {
      UPDATE: '{{name}} meninggalkan komentar'
    },
    create_company_vehicles: {
      INSERT: 'Truck created by {{name}}',
      UPDATE: 'Truck details edited by {{name}}',
      VERIFICATION: 'Truck verified by {{name}}'
    },
    save_brokerage_vendor: {
      INSERT: 'Transporter created by {{name}}',
      UPDATE: 'Basic Profile edited by {{name}}'
    },
    save_customer: {
      INSERT: '{{name}} membuat shipper',
      UPDATE: '{{name}} menyimpan shipper'
    },
    save_warehouse: {
      INSERT: '{{name}} membuat lokasi',
      UPDATE: '{{name}} menyimpan lokasi'
    },
    save_warehouse_fee: {
      INSERT: '{{name}} membuat biaya lokasi',
      UPDATE: '{{name}} menyimpan biaya lokasi'
    },
    update_partner_vehicle: {
      INSERT: 'Truck created by {{name}}',
      UPDATE: 'Truck details edited by {{name}}',
      VERIFICATION: 'Truck verified by {{name}}'
    },
    upsert_partner_company_vehicle: {
      INSERT: 'Truck created by {{name}}',
      UPDATE: 'Truck details edited by {{name}}',
      VERIFICATION: 'Truck verified by {{name}}'
    },
    verify_company_driver: {
      UPDATE: 'Driver verified by {{name}}',
      VERIFICATION: 'Driver verified by {{name}}'
    },
    verify_vendor: {
      UPDATE: 'Transporter verified by {{name}}'
    }
  },
  add_bank_account: 'Tambah Rekening Bank',
  add_coo: 'Tambah Certificate of Origin',
  add_document: 'Tambah Dokumen',
  add_ebilling_document: 'Tambah Dokumen E-Billing',
  add_employee: 'Tambah Karyawan',
  add_logo: 'Tambah Logo',
  add_msds: 'Tambah Material Safety Data Sheet',
  add_pic_contact: 'Tambah Kontak PIC',
  add_routine_order: 'Tambah Pesanan Rutin',
  add_shipping_line_delivery_order: 'Tambah Shipping Line Delivery Order',
  add_storage_charges: 'Tambah Storage Charges',
  add_transporter: 'Tambah Transporter',
  added_at: 'Ditambahkan pada',
  additional_notes: 'Catatan Tambahan',
  additional_services: 'Jasa Tambahan',
  address: 'Alamat',
  address_details: 'Detail Alamat',
  after: 'Sesudah',
  amount: 'Jumlah',
  apply: 'Terapkan',
  arrive_at_port_eta: 'Perkiraan Tiba di Pelabuhan',
  after_loading: 'Setelah Muat',
  after_unloading: 'Setelah Bongkar',
  back: 'Kembali',
  back_to_previous_page: 'Kembali ke Halaman Sebelumnya',
  bank: 'Bank',
  bank_account: 'Rekening Bank',
  before: 'Sebelum',
  book_new_route: 'Pesan Rute Baru',
  call_kargo: 'Hubungi Kargo',
  cancel: 'Batal',
  cancel_shipment: 'Batalkan Pengiriman',
  central_address: 'Alamat Pusat',
  change: 'Ubah',
  change_bill_of_lading: 'Ubah Bill of Lading',
  change_bill_of_lading_details: 'Ubah Detail Bill of Lading',
  change_delivery_order: 'Ubah Delivery Order',
  change_delivery_order_details: 'Ubah Detail Delivery Order',
  change_details: 'Ubah Detail',
  change_history: 'Riwayat Perubahan',
  change_password: 'Ubah Password',
  change_status: 'Ubah Status',
  changes: 'Perubahan',
  check_account: 'Cek Rekening',
  choose_bank: 'Pilih bank',
  choose_commodity: 'Pilih Commodity',
  choose_company: 'Pilih Perusahaan',
  choose_consignee: 'Pilih Consignee',
  choose_customs_type: 'Pilih Jenis Bea Cukai',
  choose_file: 'Pilih File',
  choose_location_details: 'Pilih Detail Lokasi',
  choose_notify_party: 'Pilih Notify Party',
  choose_port: 'Pilih Pelabuhan',
  choose_shipping_line: 'Pilih Shipping Line',
  choose_supplier: 'Pilih Supplier',
  choose_truck: 'Pilih Truk',
  city: 'Kota',
  clear: 'Hapus',
  close: 'Jam Tutup',
  commodity: 'Commodity',
  commodity_detail: 'Detail Commodity',
  company: 'Perusahaan',
  company_address: 'Alamat Perusahaan',
  company_details: 'Detail Perusahaan',
  company_document: 'Dokumen Perusahaan',
  company_name: 'Nama Perusahaan',
  company_settings: 'Pengaturan Perusahaan',
  company_status_map: {
    [COMPANY_STATUSES.ACTIVE]: 'Aktif',
    [COMPANY_STATUSES.INACTIVE]: 'Nonaktif',
    [COMPANY_STATUSES.REGISTERED]: 'Terdaftar',
    [COMPANY_STATUSES.CHURN]: 'Churn',
    [COMPANY_STATUSES.DEACTIVATED]: 'Dinonaktifkan'
  },
  complete_address: 'Alamat Lengkap',
  confirm_your_availability: 'Konfirmasi ketersediaan Anda',
  consignee: 'Consignee',
  contact: 'Kontak',
  contact_name: 'Nama Kontak',
  contact_team_kargo: ' Hubungi Tim Kargo',
  contacts: 'Kontak',
  container: 'Container',
  continue: 'Lanjut',
  coo: 'Certificate of Origin',
  create_bill_of_lading: 'Buat Bill Of Lading',
  create_delivery: 'Buat Delivery Order',
  create_new_warehouse: 'Buat Gudang Baru',
  customer_details: 'Detail Customer',
  customer_division: {
    FINANCE: 'Finance',
    OPERATIONS: 'Operations',
    SALES: 'Sales',
    WAREHOUSE: 'Warehouse'
  },
  customer_name_slash_company: 'Nama Customer / Perusahaan',
  customs_document: 'Dokumen Bea Cukai',
  customs_type: 'Jenis Bea Cukai',
  customs_type_map: {
    BC20: 'BC 2.0',
    BC23: 'BC 2.3',
    BC25: 'BC 2.5'
  },
  date: 'Tanggal',
  date_due: 'Tanggal Due',
  date_issued: 'Tanggal Issued',
  day: 'Hari',
  days: 'Hari',
  damage: 'Kerusakan',
  dashboard: 'Dashboard',
  deactivate: 'Nonaktifkan',
  default_account: 'Rekening Utama',
  delete: 'Hapus',
  delete_account: 'Hapus Rekening',
  delete_bill_of_lading: 'Hapus Bill of Lading',
  delivery_confermission_title: 'Ringkasan Delivery Order',
  delivery_distance: 'Jarak Pengiriman',
  delivery_order: 'Delivery Order',
  description: 'Deskripsi',
  destination: 'Tujuan',
  destination_pic: 'PIC Lokasi Bongkar',
  destination_port: 'Pelabuhan Tujuan',
  detail: 'Rincian',
  detail_title: 'Detail',
  dimension: 'Dimension',
  district: 'Kecamatan',
  division: 'Divisi',
  do_cancellation_reason_map: {
    ALLOCATION_REVISION: 'Perubahan alokasi, perubahan shipment',
    COMPLIANCE_ISSUE: 'Issue compliance',
    FORCE_MAJEURE: 'Bencana alam',
    FULL_STOCK: 'Full stock at customer',
    LOADING_DELAY: 'Masalah proses loading di pabrik',
    NO_EMPTY_JUGS: 'Tidak ada galon kosong (khusus kiriman galon)',
    NO_PALLET_JUGRACK: 'Tidak ada pallet / jugrack tersedia',
    NO_STOCK: 'Tidak ada stock di pabrik / production issue',
    OTHER_REASON: 'Alasan lain yang tidak disebutkan diatas',
    TKBM_ISSUE: 'Issue TKBM',
    TRUCK_NO_SHOW: 'Truck tidak datang, auto expired'
  },
  do_margin: 'Margin',
  do_max_bid_limit: 'Max Bid Limit',
  do_type: 'Tipe DO',
  document: 'Dokumen',
  document_name: 'Document Name',
  document_number: 'Nomor {{name}}',
  document_type: 'Jenis Dokumen',
  document_type_map: {
    CAR: 'Klaim dan Pengembalian',
    POD: 'SJ Bongkar',
    POL: 'SJ Muat',
    REJECTED_GOODS: 'Kondisi Barang',
    REJECTION_LETTER: 'Berita Acara Tolakan'
  },
  document_upload_details: 'Buka detail pengiriman untuk melihat dokumen',
  documents: 'Dokumen',
  dof: 'DOF',
  done: 'Selesai',
  down_payment: 'Uang Muka',
  download: 'Unduh',
  download_csv: 'Unduh CSV',
  download_documents: 'Unduh Dokumen',
  download_documents_from_external: 'Unduh Dari Web',
  driver: 'Supir',
  driver_as_helper: 'Supir sebagai Kenek',
  driver_helper: 'Driver Helper',
  driver_notes: 'Catatan untuk Supir',
  driver_online: 'Driver Online',
  driver_offline: 'Driver Offline',
  driver_phone: 'Telepon Supir',
  drivers: 'Supir',
  dropoff: 'Tujuan',
  ebilling_document: 'Dokumen E-Billing',
  ebilling_paid_time: 'Waktu Pembayaran E-Billing',
  edit: 'Ubah',
  edit_employee: 'Ubah Karyawan',
  edit_transporter: 'Ubah Transporter',
  email: 'Email',
  employee_details: 'Detail Karyawan',
  employees: 'Karyawan',
  end_date: 'Tanggal Berakhir',
  end_time: 'Waktu Berakhir',
  eta: 'Perkiraan Tiba',
  external_id: 'ID Eksternal',
  extra_helper: 'Extra Helper',
  file_not_selected_yet: 'File belum dipilih',
  file_upload_source_map: {
    DA: 'Aplikasi Mitra',
    TMS: 'Vendor Central'
  },
  fill_routes_and_trucks: 'Isi Rute dan Truk',
  find_another_payload: 'Cari muatan lain',
  forgot_password: 'Lupa Password',
  free_time_end_date: 'Tanggal Berakhir Free Time',
  full_address: 'Alamat Lengkap',
  go_back_and_reset_form: 'Kembali dan Reset Form',
  goods_type: 'Jenis Muatan',
  goods_near_expiry_date: 'Barang Hampir Kadaluarsa',
  goods_too_few: 'Barang Kurang',
  goods_too_many: 'Barang Terlalu Banyak',
  gps_not_available: 'GPS tidak tersedia',
  hours: 'Jam',
  house_bill_of_lading: 'House Bill Of Lading',
  house_bill_of_lading_number: 'No. House Bill Of Lading',
  id_card: 'KTP',
  immediately_confirm: 'Segera konfirmasi',
  inactive: 'Tidak Aktif',
  including_insurance: 'termasuk Asuransi',
  insights: 'Insights',
  invoice: 'Invoice',
  invoice_details: 'Detail Invoice',
  invoice_document: 'Dokumen Invoice',
  invoice_number: 'Nomor Invoice',
  item: 'Item',
  late_docs_end_date: 'Tanggal Berakhir Late Docs',
  late_docs_start_date: 'Tanggal Mulai Late Docs',
  latest_pickup: 'Muat Terakhir',
  latitude: 'Latitude',
  lead_time: 'Lead Time',
  leave_page: 'Tinggalkan Halaman',
  license_plate: 'No. Polisi',
  limited_hours: 'Jam tertentu',
  line: 'Baris',
  list: 'Daftar',
  load: 'Muat',
  load_and_unload: 'Muat & Bongkar',
  load_date: 'Tanggal Muat',
  load_date_and_time: 'Tanggal & Waktu Muat',
  load_location: 'Lokasi Muat',
  load_schedule: 'Jadwal Muat',
  load_time: 'Waktu Muat',
  load_value: 'Nilai Barang',
  loading_date: 'Jadwal Muat',
  local_shipment_statuses: {
    [LOCAL_SHIPMENT_STATUSES.UPCOMING]: 'Butuh Konfirmasi',
    [LOCAL_SHIPMENT_STATUSES.PLANNED]: 'Dijadwalkan',
    [LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE]: 'Di Warehouse',
    [LOCAL_SHIPMENT_STATUSES.IN_TRANSIT]: 'Sedang Transit',
    [LOCAL_SHIPMENT_STATUSES.ONGOING]: 'Berlangsung',

    // To Be Translateb by Proshanto
    [LOCAL_SHIPMENT_STATUSES.AT_LOCATION]: 'At Location',
    [LOCAL_SHIPMENT_STATUSES.COMPLETED]: process.env.DO_DISPATCH_AND_ALLOCATION_STMS_TTMS
      ? 'Riwayat'
      : 'Selesai',
    [LOCAL_SHIPMENT_STATUSES.HISTORY]: 'Riwayat',
    [LOCAL_SHIPMENT_STATUSES.ALL]: 'All',
  },
  location: 'Lokasi',
  location_and_warehouse: 'Lokasi dan Gudang',
  location_code: 'Kode Lokasi',
  location_details: 'Detail Lokasi',
  location_from_google_map: 'Lokasi dari Google Map',
  location_name: 'Nama lokasi',
  location_status_map: {
    DEACTIVATED: 'Inactive',
    REGISTERED: 'Active'
  },
  location_type: {
    HOLDING: 'Lokasi Penitipan',
    NORMAL: 'Normal',
    PORT: 'Pelabuhan',
    RETURN_DEPO: 'Return Depo',
    STORE: 'Store'
  },
  login: 'Masuk',
  login_continue: 'Masuk Kargo Shipper',
  logo: 'Logo',
  logout: 'Keluar',
  longitude: 'Longitude',
  measurement_unit: 'Satuan',
  missing_goods: 'Barang Hilang',
  monitoring: 'Monitoring',
  more_filters: 'Filter Lainnya',
  msds: 'Material Safety Data Sheet',
  name: 'Nama',
  negotiation_price: 'Harga Negosiasi',
  new_bill_of_lading: 'Bill of Lading Baru',
  new_customer: 'Customer Baru',
  new_delivery_order: 'Delivery Order Baru',
  new_order: 'Pesanan Baru',
  new_password: 'Password Baru',
  new_warehouse: 'Gudang Baru',
  next: 'Lanjut',
  new_routine_order: 'Pesanan Rutin Baru',
  no_container: 'No. Container',
  no_of_trucks: 'Jumlah Truk',
  no_po: 'No. Po',
  noa: 'NOA',
  notes_for_driver: 'Catatan untuk Supir',
  notification: 'Notifikasi',
  notify_party: 'Notify Party',
  num_of_helper: 'Jumlah Kenek',
  number: 'Nopol',
  ok: 'Ok',
  ongoing_shipment: 'Pengiriman Berlangsung',
  open: 'Jam Buka',
  open_24_hrs: 'Buka 24 jam',
  operating_hours: 'Jam Operasional',
  operational_hours: 'Jam Operasional',
  optional: 'Tidak Wajib',
  order: 'Pengiriman',
  order_truck: 'Pesan Truk',
  order_type: 'Jenis Pesanan',
  origin: 'Asal',
  origin_pic: 'PIC Lokasi Muat',
  other: 'Lainnya',
  other_location: 'Lokasi Lainnya',
  owner_name: 'Nama Pemilik',
  packaging_damage: 'Kemasan Rusak',
  panthera_do_number: 'DO Number panthera',
  password: 'Password',
  payment_and_tax_details: 'Detail Pembayaran & Pajak',
  payment_details: 'Detail Pembayaran',
  payment_method: 'Metode Pembayaran',
  payment_scheme: 'Skema Pembayaran',
  personal_account: 'Akun Pribadi',
  phone: 'Telepon',
  phone_number: 'Nomor Handphone',
  pib: 'PIB',
  pic_name: 'Nama PIC',
  pic_phone: 'Telepon PIC',
  pic_warehouse_location: 'PIC Gudang / Lokasi',
  pickup: 'Asal',
  planned_date: 'Jadwal',
  planned_dates: 'Jadwal',
  po_number: 'No. PO',
  pod_return: 'Jasa POD Return',
  pod_return_location: 'Lokasi POD Return',
  podf: 'PODF',
  pph23: 'PPh 23',
  pph23_deductor: 'Pemotong PPh23',
  pph23_deductors: {
    PARTNER: 'Customer',
    SELF: 'PT saya'
  },
  pre_alert_document: 'Dokumen Pre-Alert',
  premium_insurance: 'Asuransi Premium',
  price: 'Harga',
  price_based_on_distance: 'Harga berdasarkan jarak',
  price_detail: 'Detail Harga',
  price_not_found: 'Harga Tidak Ditemukan',
  pricing: 'Harga',
  pricing_settings: 'Pricing Settings',
  product_category_map: {
    [PRODUCT_CATEGORY.ALCOHOLIC_BEVARAGES]: 'Minuman Beralkohol',
    [PRODUCT_CATEGORY.DRINKING_WATER]: 'Air Minum',
    [PRODUCT_CATEGORY.COFFEE]: 'Kopi',
    [PRODUCT_CATEGORY.FRUIT_FLAVORED_DRINKS]: 'Minuman Rasa Buah',
    [PRODUCT_CATEGORY.JUICE]: 'Jus',
    [PRODUCT_CATEGORY.MILK]: 'Susu',
    [PRODUCT_CATEGORY.NON_DAIRY]: 'Susu Non-Susu',
    [PRODUCT_CATEGORY.POWDERED_BEVERAGES_MIXES]: 'Campuran Minuman Serbuk',
    [PRODUCT_CATEGORY.SODA]: 'Soda',
    [PRODUCT_CATEGORY.SPORTS_ENERGY_DRINKS]: 'Olahraga & Minuman Energi',
    [PRODUCT_CATEGORY.TEA_INFUSIONS]: 'Teh & Infus',
    [PRODUCT_CATEGORY.WATER]: 'Air',
    [PRODUCT_CATEGORY.BAKERY]: 'Toko Roti',
    [PRODUCT_CATEGORY.SNAKES]: 'Makanan Ringan',
    [PRODUCT_CATEGORY.CONDIMENTS_SOUCES]: 'Bumbu & Saus',
    [PRODUCT_CATEGORY.COOKING_BAKING_INGREDIENTS]: 'Bahan Memasak & Memanggang',
    [PRODUCT_CATEGORY.DAIRY]: 'Produk Susu (Dingin)',
    [PRODUCT_CATEGORY.DIPS]: 'Dips & Spread',
    [PRODUCT_CATEGORY.FOOD]: 'Keranjang Hadiah Makanan',
    [PRODUCT_CATEGORY.FROZEN]: 'Makanan Penutup & Hal Baru Beku',
    [PRODUCT_CATEGORY.FRUITS]: 'Buah & Sayuran',
    [PRODUCT_CATEGORY.GRAINS]: 'Biji-bijian, Beras & Sereal',
    [PRODUCT_CATEGORY.MEAT]: 'Daging, Makanan Laut & Telur',
    [PRODUCT_CATEGORY.NUTS]: 'Kacang & Biji',
    [PRODUCT_CATEGORY.PASTA]: 'Pasta & Mie',
    [PRODUCT_CATEGORY.PREPARED]: 'Makanan Jadi',
    [PRODUCT_CATEGORY.SPICES]: 'Bumbu & Rempah',
    [PRODUCT_CATEGORY.SNACKS]: 'Makanan Ringan',
    [PRODUCT_CATEGORY.SOUPS]: 'Sup & Kaldu',
    [PRODUCT_CATEGORY.TOUFU]: 'Produk Tahu, Kedelai & Vegetarian',
    [PRODUCT_CATEGORY.FITNESS_AND_NUTRITION]: 'Kebugaran & Nutrisi',
    [PRODUCT_CATEGORY.MEDICINE_AND_DRUGS]: 'Obat & Obat',
    [PRODUCT_CATEGORY.FMCG]: 'FMCG',
    [PRODUCT_CATEGORY.PLASTIC]: 'Pellet Plastik',
    [PRODUCT_CATEGORY.PACKAGING]: 'Kemasan'
  },
  product_details: 'Detail Muatan',
  province: 'Provinsi',
  quarantine_date: 'Tanggal Karantina',
  quarantine_document: 'Dokumen Karantina',
  quarantine_type: 'Jenis Karantina',
  receiver_detail: 'Detail Penerima',
  register: 'Daftar',
  registered_to_swift_payment_program: 'Terdaftar Program Pembayaran Cepat',
  regular_order_list: 'Daftar Pesanan Rutin',
  remaining_lead_time: 'Sisa Lead Time',
  resend_verification_code: 'Kirim ulang kode',
  retype_new_password: 'Ulangi Password Baru',
  retype_password: 'Ulangi Password',
  route: 'Rute',
  routes: 'Rute',
  routine_order: 'Pesanan Rutin',
  save: 'Simpan',
  save_changes: 'Simpan Perubahan',
  save_customer: 'Simpan Customer',
  save_location: 'Simpan Lokasi',
  save_password: 'Simpan Password',
  save_transporter: 'Simpan Transporter',
  save_warehouse: 'Simpan Gudang',
  saved_warehouse: 'Lokasi Tersimpan',
  search: 'Cari',
  search_address: 'Cari Alamat',
  search_location: 'Cari Lokasi',
  search_shipment: 'No. Order, External ID, Nopol',
  see_more: 'Lihat selengkapnya',
  see_more_routine_orders: 'Lihat Pesanan Rutin Lainnya',
  select_as: 'pilih sebagai',
  service_inactive: 'Layanan Tidak Aktif',
  service_active: 'Layanan Aktif',
  shipment: 'Pengiriman',
  shipment_details: 'Detail Pengiriman',
  shipment_direction: 'Arah Pengiriman',
  shipment_document_type_map: {
    'claims-returns': 'Klaim dan Pengembalian',
    'internal-pod': 'SJ Bongkar',
    'internal-pol': 'SJ Muat'
  },
  shipment_drop: 'Multi-Drop',
  shipment_no: 'No. Pengiriman',
  shipment_number: 'Nomor Pengiriman',
  shipment_pickup: 'Muat',
  shipment_status_map: {
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'Tiba di Lokasi Bongkar',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'Tiba di Lokasi Muat',
    [DERIVED_SHIPMENT_STATUSES.BEHIND_THE_SCHEDULE]: 'Behind Schedule',
    [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'Customer Membatalkan',
    [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Dibatalkan',
    [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'Konfirmasi Terlewat',
    [DERIVED_SHIPMENT_STATUSES.ON_TIME]: 'On Time',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_DESTINATION]: 'Queueing At Destination',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_AT_ORIGIN]: 'Queueing At Origin',
    [DERIVED_SHIPMENT_STATUSES.QUEUEING_OVERTIME]: 'Queueing Overtime',
    [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'Customer Mengalihkan Penugasan',
    [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'Tidak Terpenuhi',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Pengiriman Selesai',
    [SHIPMENT_STATUSES.START_LOADING]: 'Mulai Proses Muat',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Mulai Proses Bongkar',
    [SHIPMENT_STATUSES.JOB_UNASSIGNED]: 'Menunggu Truk',
    [SHIPMENT_STATUSES.JOB_BIDDING]: 'Menunggu Truk',
    [SHIPMENT_STATUSES.PLANNED]: 'Menunggu Truk',
    [SHIPMENT_STATUSES.ASSIGNED]: 'Truk Terkonfirmasi',
    [SHIPMENT_STATUSES.UPCOMING]: 'Menunggu Truk',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'Menuju Lokasi Muat',
    [SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION]: 'Dokumen Diunggah',
    [SHIPMENT_STATUSES.ONGOING_TO_DESTINATION]: 'Menuju Bongkar',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Proses Muat Selesai',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'Menuju Lokasi Bongkar',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Proses Bongkar Selesai',
    [SHIPMENT_STATUSES.WAITING_FOR_TRUCK]: 'Menunggu Truk',
    [SHIPMENT_STATUSES.TRUCK_CONFIRMED]: 'Truk Terkonfirmasi',
    [SHIPMENT_STATUSES.SHIPMENT_CANCELLED]: 'Pengiriman Dibatalkan',
    [SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'Pengiriman Dibatalkan',
    [SHIPMENT_STATUSES.UNFULFILLED]: 'Pengiriman Dibatalkan'
  },
  shipment_type: 'Jenis Pengiriman',
  shipments: 'Pengiriman',
  shipper: 'Shipper',
  shipper_location_operating_day_map: {
    [SHIPPER_LOCATION_OPERATING_DAY.MONDAY]: 'Senin',
    [SHIPPER_LOCATION_OPERATING_DAY.TUESDAY]: 'Selasa',
    [SHIPPER_LOCATION_OPERATING_DAY.WEDNESDAY]: 'Rabu',
    [SHIPPER_LOCATION_OPERATING_DAY.THURSDAY]: 'Kamis',
    [SHIPPER_LOCATION_OPERATING_DAY.FRIDAY]: 'Jumat',
    [SHIPPER_LOCATION_OPERATING_DAY.SATURDAY]: 'Sabtu',
    [SHIPPER_LOCATION_OPERATING_DAY.SUNDAY]: 'Minggu',
    [SHIPPER_LOCATION_OPERATING_DAY.WEEKDAY]: 'Hari kerja',
    [SHIPPER_LOCATION_OPERATING_DAY.WEEKEND]: 'Akhir pekan',
    [SHIPPER_LOCATION_OPERATING_DAY.HOLIDAY]: 'Libur nasional'
  },
  shipping_cost: 'Biaya Pengiriman',
  shipper_fault: 'Kesalahan Shipper',
  shipping_line: 'Shipping Line',
  shipping_line_delivery_order: 'Shipping Line Delivery Order',
  shipping_route: 'Rute Pengiriman',
  skip: 'Nanti Saja',
  sku: 'sku',
  source_port: 'Pelabuhan Asal',
  special_needs: 'Kebutuhan Khusus',
  sppb: 'SPPB',
  sppkp: 'SPPKP',
  stage: 'Tahap',
  start_date: 'Tanggal Mulai',
  start_time: 'Waktu Mulai',
  status: 'Status',
  status_change_timestamp: 'Waktu Perubahan Status',
  status_log: 'Status Log',
  stay_in_form: 'Tetap di Form',
  storage_charges: 'Storage Charges',
  store: 'Store',
  submitted_bid: 'Submitted Bid',
  supplier: 'Supplier',
  tags: 'Label',
  tax_document: 'Faktur Pajak',
  telephone_no: 'No. Telepon',
  things: 'hal',
  tila: 'TILA',
  tin: 'NPWP',
  to_display_shipping_price: 'untuk menampilkan harga pengiriman',
  tonnage: 'Kapasitas',
  tonnage_or_cubication: 'Berat Muatan',
  total_finished_unloading: 'Total Proses Bongkar',
  total_finished_loading: 'Total Proses Muat',
  total_price: 'Total Harga',
  total_shipment: 'Total Pengiriman',
  tracking: 'Pelacakan',
  transit_time: 'Transit Time',
  transporter: 'Transporter',
  transporter_details: 'Detail Transporter',
  travelling_expenses: 'Uang Jalan',
  truck: 'Truk',
  truck_and_driver: 'Truk & Supir',
  truck_monitoring: 'Truck Monitoring',
  truck_monitoring_warehouse_status_map: {
    [DELIVERY_ORDER_WAREHOUSE_FILTER_TYPE.ALL]: 'Semua Arah',
    [DELIVERY_ORDER_WAREHOUSE_FILTER_TYPE.INBOUND]: 'Menuju Lokasi',
    [DELIVERY_ORDER_WAREHOUSE_FILTER_TYPE.OUTBOUND]: 'Berangkat dari Lokasi'
  },
  truck_request: 'Permintaan Truk',
  truck_type: 'Jenis Truk',
  type: 'Jenis',
  type_of_access: 'Jenis Akses',
  type_of_company: 'Jenis Perusahaan',
  type_of_goods: 'Jenis Barang',
  type_of_payment: 'Jenis Pembayaran',
  unit: 'Tonase / Kubikasi',
  unload: 'Bongkar',
  unload_date_and_time: 'Tanggal & Waktu Bongkar',
  unload_location: 'Lokasi Bongkar',
  unloading_date: 'Jadwal Bongkar',
  unloading_time: 'Waktu Bongkar',
  updated: 'Diperbarui pada',
  updated_at: 'Diperbarui Pada',
  upload: 'Unggah',
  upload_bill_of_lading: 'Unggah Bill Of Lading',
  upload_coo: 'Unggah Certificate of Origin',
  upload_csv: 'Unggah CSV',
  upload_document: 'Unggah Dokumen',
  upload_ebilling_document: 'Unggah Dokumen E-Billing',
  upload_house_bill_of_lading: 'Unggah House Bill Of Lading',
  upload_invoice_document: 'Unggah Dokumen Invoice',
  upload_logo: 'Unggah Logo',
  upload_message: 'Untuk mengunggah file ini, Andupdatea harus mengubah',
  upload_msds: 'Unggah Material Safety Data Sheet',
  upload_noa: 'Unggah NOA',
  upload_npwp: 'Unggah NPWP',
  upload_pib: 'Unggah PIB',
  upload_quarantine_document: 'Unggah Dokument Karantina',
  upload_shipping_line_delivery_order: 'Unggah Delivery Order',
  upload_sppb: 'Unggah SPPB',
  upload_sppkp: 'Unggah SPPKP',
  upload_storage_charges: 'Unggah Storage Charges',
  upload_tax_document: 'Unggah Faktur Pajak',
  upload_the_id_card: 'Unggah KTP',
  upload_tila: 'Unggah TILA',
  uploaded_from: 'Diunggah dari',
  user: 'User',
  user_access_type_map: {
    [USER_ACCESS_TYPE.ADMIN]: 'Admin',
    [USER_ACCESS_TYPE.FULL_ACCESS]: 'Akses Tidak Terbatas',
    [CUSTOMER_DIVISION.FINANCE]: 'Akses Finansial',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.FINANCE_ADMIN]: 'Admin Finansial',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.VENDOR_MANAGEMENT]: 'Manajemen vendor',
    [USER_ACCESS_TYPE.RESTRICTED_ACCESS]: 'Akses Terbatas',
    [CUSTOMER_DIVISION.SALES]: 'Sales',
    [PANTHERA_PIC_DIVISION.SALES_SUPPORT]: 'Sales Support',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.AR]: 'Account Receivable',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.TRAFFIC_CONTROLLER]: 'Traffic Controller',
    [BROKERAGE_EXTRA_USER_ACCESS_TYPE.DOCUMENT_COLLECTION]: 'Document Collection',
    [FIRST_MILE_USER_ACCESS_TYPE.FREIGHT_FORWARDER]: 'Freight Forwarder',
    [FIRST_MILE_USER_ACCESS_TYPE.CONSIGNEE]: 'Consignee',
    [USER_SALES_ROLE.SME_TELESALES]: 'SME Tele Sales',
    [USER_SALES_ROLE.SME_SALES_EXECUTIVE]: 'SME Sales Executive',
    [USER_SALES_ROLE.SME_TEAM_LEADER]: 'SME Team Leader',
    [PANTHERA_PIC_DIVISION.CANVASSER]: 'Canvasser'
  },
  user_status_map: {
    [USER_STATUSES.ACTIVE]: 'Aktif',
    [USER_STATUSES.REGISTERED]: 'Belum Aktif',
    [USER_STATUSES.INACTIVE]: 'Nonaktif'
  },
  valid_until: 'Berlaku Hingga',
  vehicle_body_type: 'Karoseri',
  vehicle_type: 'Jenis Truk',
  vehicles: 'Armada',
  vendor: 'Vendor',
  vendor_code: 'Kode vendor',
  vendor_id: 'ID vendor',
  vendor_name: 'Nama pedagang',
  vendor_registration_id: 'ID Pendaftaran Vendor',
  vendors: 'Vendor',
  verification_code: 'Kode Verifikasi',
  vessel_detail: 'Detail Kapal',
  vessel_name: 'Nama Kapal',
  view_changes: 'Lihat Perubahan',
  view_delivery: 'Lihat Pengiriman',
  view_map: 'Lihat Peta',
  view_on_map: 'Lihat di Peta Dashboard',
  view_truck_list: 'Lihat Daftar Pengiriman',
  voyage_number: 'No. Voyage',
  warehouse: 'Gudang',
  warehouse_location_pic: 'PIC Gudang / Lokasi',
  warehouse_name: 'Nama Gudang',
  wrong_goods: 'Salah Kirim barang',
};
