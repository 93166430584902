import { FIRST_MILE_CONTAINER_SIZES, FIRST_MILE_DOCUMENT_TYPES } from '~/Configurations/constants';

export default {
  bill_of_lading: 'Bill of Lading',
  bill_of_lading_document_type: {
    [FIRST_MILE_DOCUMENT_TYPES.HOUSE_BILL_OF_LADING]: 'House Bill of Lading',
    [FIRST_MILE_DOCUMENT_TYPES.BILL_OF_LADING]: 'Bill of Lading',
    [FIRST_MILE_DOCUMENT_TYPES.NOTICE_OF_ARRIVAL]: 'NOA',
    [FIRST_MILE_DOCUMENT_TYPES.PIB]: 'PIB',
    [FIRST_MILE_DOCUMENT_TYPES.QUARANTINE]: 'Karantina',
    [FIRST_MILE_DOCUMENT_TYPES.EBILLING]: 'E-Billing',
    [FIRST_MILE_DOCUMENT_TYPES.SHIPPING_LINE_DELIVERY_ORDER]: 'Shipping Line Delivery Order',
    [FIRST_MILE_DOCUMENT_TYPES.MATERIAL_SAFETY_AND_DATA_SHEET]: 'Material & Safety Data Sheet',
    [FIRST_MILE_DOCUMENT_TYPES.CERTIFICATE_OF_ORIGIN]: 'Certificate of Origin',
    [FIRST_MILE_DOCUMENT_TYPES.STORAGE_CHARGES]: 'Storage Charges',
    [FIRST_MILE_DOCUMENT_TYPES.SPPB]: 'SPPB'
  },
  bill_of_lading_number: 'No. $t(first_mile:bill_of_lading)',
  bill_of_lading_summary: 'Ringkasan Bill of Lading',
  bulk_edit: 'Edit Bulk',
  container_all: 'Semua Container',
  container_arrival_eta: 'Perkiraan Tiba di Store',
  container_attachments: 'Attachment Container',
  container_detail: 'Detail Container',
  container_document_type: {
    [FIRST_MILE_DOCUMENT_TYPES.TILA]: 'TILA',
    [FIRST_MILE_DOCUMENT_TYPES.CONTAINER_RENTAL]: 'Surat Peminjaman Kontainer',
    [FIRST_MILE_DOCUMENT_TYPES.PACKING_LIST]: 'Packing List',
    [FIRST_MILE_DOCUMENT_TYPES.PROOF_OF_DELIVERY]: 'Surat Jalan',
    [FIRST_MILE_DOCUMENT_TYPES.SHIPPING_LINE_DELIVERY_ORDER]: 'Shipping Line Delivery Order',
    [FIRST_MILE_DOCUMENT_TYPES.MATERIAL_SAFETY_AND_DATA_SHEET]: 'Material & Safety Data Sheet',
    [FIRST_MILE_DOCUMENT_TYPES.LOLO]: 'LOLO'
  },
  container_holding_area: 'Lokasi Penitipan',
  container_in_holding: 'Dalam Penitipan',
  container_number: 'No. Container',
  container_return_depo: 'Return Depo',
  container_sizes: {
    [FIRST_MILE_CONTAINER_SIZES['20_FEET']]: '20"',
    [FIRST_MILE_CONTAINER_SIZES['20_FR']]: '20" FR',
    [FIRST_MILE_CONTAINER_SIZES['20_GP']]: '20" GP',
    [FIRST_MILE_CONTAINER_SIZES['20_OT']]: '20" OT',
    [FIRST_MILE_CONTAINER_SIZES['20_OW']]: '20" OW',
    [FIRST_MILE_CONTAINER_SIZES['40_FEET']]: '40"',
    [FIRST_MILE_CONTAINER_SIZES['40_FR']]: '40" FR',
    [FIRST_MILE_CONTAINER_SIZES['40_GP']]: '40" GP',
    [FIRST_MILE_CONTAINER_SIZES['40_HC']]: '40" HC',
    [FIRST_MILE_CONTAINER_SIZES['40_OT']]: '40" OT',
    [FIRST_MILE_CONTAINER_SIZES['40_OW']]: '40" OW',
    [FIRST_MILE_CONTAINER_SIZES['45_FEET']]: '45"'
  },
  container_status: {
    ARRIVED_AT_PORT: 'Sampai di Pelabuhan',
    INVOICED: 'Ditagih',
    MOVED_TO_HOLDING: 'Dipindah ke penitipan',
    NO_NOA: 'NOA belum diterima',
    NOA: 'NOA',
    ON_SHIPMENT: 'Dalam Perjalanan',
    PAID: 'Dibayar',
    PAYMENT_PENDING: 'Pembayaran Pending',
    PIB: 'PIB',
    QUARANTINED: 'Dikarantina',
    SHIPMENT_COMPLETE: 'Selesai',
    SPPB: 'SPPB',
    WAITING_FOR_ORIGINAL_DOC: 'Menunggu Original Doc',
    WAITING_FOR_SPPB: 'Menunggu SPPB'
  },
  container_type: 'Jenis Container',
  container_unloading_capacity: 'Kapasitas Bongkar Harian',
  container_use_holding: 'Penitipan',
  container_using_holding: 'Container Dititip',
  container_weight: 'Berat Container',
  dashboard: 'Dashboard',
  date_placed_in_holding: 'Tanggal dititip',
  delivery_order_attachments: 'Attachment Delivery Order',
  location_detail: 'Detail Lokasi',
  number_of_containers: 'Jumlah Container',
  other_documents: 'Dokumen Lain',
  port_address: 'Alamat Pelabuhan',
  port_detail: 'Detail Pelabuhan',
  schedule_report: 'Schedule Report',
  seal_number: 'No. Seal',
  store_arrival_eta: 'Perkiraan Tiba di Store',
  store_holding: 'Penitipan',
  terminal: 'Terminal',
  volume_report: 'Volume Report'
};
