import { get } from 'lodash-es';

export const getUserProfile = data => {
  const employeeProfile = get(data, 'myEmployeeProfile');
  const companyDetails = get(data, 'myEmployeeProfile.company');
  const companyDocuments = get(data, 'companySettings.companyDocuments');
  const companyBankAccounts = get(data, 'companyBankAccounts');
  const enrolledCompanyKsuids = get(data, 'enrolledCompanies') || [];
  const IS_AS_SHIPPER_PLATFORM = process.env.REACT_APP_CLIENT_MODE === 'SHIPPER_PLATFORM';

  return {
    ksuid: get(employeeProfile, 'ksuid') || '',
    name: get(employeeProfile, 'name') || '',
    email: get(employeeProfile, 'user.email') || '',
    phoneNumber: get(employeeProfile, 'user.phoneNumber') || '',
    defaultLocale: get(employeeProfile, 'user.defaultLocale'),
    company: {
      accessType: get(employeeProfile, 'accessType', ''),
      accessTypes: get(employeeProfile, 'accessTypes', []),
      address: get(companyDetails, 'address') || '',
      email: get(companyDetails, 'email') || '',
      handle: get(companyDetails, 'handle'),
      hasConsentedCustomer: Boolean(get(companyDetails, 'hasConsentedCustomer')),
      isDofEligible: Boolean(get(companyDetails, 'isDofEligible')),
      isPodfEligible: Boolean(get(companyDetails, 'isPodfEligible')),
      ksuid: get(companyDetails, 'ksuid'),
      logo: {
        url: get(companyDetails, 'logo.url.full'),
        fileName: get(companyDetails, 'logo.fileName') || ''
      },
      metadata: get(companyDetails, 'metadata') || '',
      name: IS_AS_SHIPPER_PLATFORM ? get(companyDetails, 'aliasName') || '' : get(companyDetails, 'name') || '',
      phoneNumber: get(companyDetails, 'phoneNumber') || '',
      status: get(companyDetails, 'status'),
      type: get(companyDetails, 'type'),
      documents: {
        ktp: {
          url: get(companyDocuments, 'ktpFile.url.full'),
          fileName: get(companyDocuments, 'ktpFile.fileName') || '',
          uploadedAt: get(companyDocuments, 'ktpFile.uploadedAt.datetime')
        },
        npwp: {
          url: get(companyDocuments, 'npwpFile.url.full'),
          fileName: get(companyDocuments, 'npwpFile.fileName') || '',
          uploadedAt: get(companyDocuments, 'npwpFile.uploadedAt.datetime')
        },
        sppkp: {
          url: get(companyDocuments, 'sppkpFile.url.full'),
          fileName: get(companyDocuments, 'sppkpFile.fileName') || '',
          uploadedAt: get(companyDocuments, 'sppkpFile.uploadedAt.datetime')
        }
      }
    },
    bankAccounts: Array.isArray(companyBankAccounts)
      ? companyBankAccounts.map(account => ({
        ksuid: get(account, 'ksuid'),
        accountHolder: get(account, 'accountHolder'),
        accountNumber: get(account, 'accountNumber'),
        isDefault: get(account, 'isDefault'),
        bank: {
          ksuid: get(account, 'bank.ksuid'),
          name: get(account, 'bank.name')
        }
      }))
      : [],
    enrolledCompanies: enrolledCompanyKsuids.map(({ ksuid } = {}) => ksuid),
  };
};

export default getUserProfile;
