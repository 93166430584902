import React, { createContext } from 'react';
import platformConfig from '../Configurations/platform';
import i18n from '~/Services/i18n';
import tracker from '~/Services/tracker';

const { lang: DEFAULT_LANG } = platformConfig;

if (platformConfig.lang) {
  i18n.changeLanguage(DEFAULT_LANG);
}

if (process.env.REACT_APP_CLEVERTAP_PROJECT_ID) {
  tracker.initialize(process.env.REACT_APP_CLEVERTAP_PROJECT_ID);
}

export const AppContext = createContext(platformConfig);

function AppProvider({ children }) {
  return (
    <AppContext.Provider value={platformConfig}>
      {children}
    </AppContext.Provider>
  );
}

export default AppProvider;
