import { FIRST_MILE_USER_ACCESS_TYPE, USER_ACCESS_TYPE } from '~/Configurations/constants';

/* eslint-disable max-len */
export default {
  add_account_helper_text: 'Tambah rekening bank yang akan dicantumkan di invoice',
  already_have_an_account: 'Sudah mempunyai akun?',
  bank_account_successfully_saved: 'Rekening bank tersimpan',
  before_preceded_by_another_vendor: 'sebelum Anda didahului vendor lain',
  bid_loose_notification_msg_end: 'belum terpilih.',
  bid_loose_notification_msg_start: 'Tawaran Anda untuk',
  bid_win_notification_msg_end: 'terpilih!',
  bid_win_notification_msg_start: 'Selamat, tawaran Anda untuk',
  bill_of_lading_successfully_deleted: 'Bill of Lading berhasil dihapus',
  bill_of_lading_successfully_saved: 'Bill of Lading berhasil disimpan',
  best_price_of_kargo: 'Harga Terbaik dari Kargo (termasuk Asuransi)',
  cancel_do_warning: 'Pengiriman ini akan tetap tersimpan, namun tidak akan bisa diaktifkan kembali.',
  change_shipment_status_message_title: 'Mengubah status akan....',
  choose_container_to_edit: 'Pilih container untuk diedit',
  contract_price_including_insurance: 'Harga Sesuai Kontrak (termasuk Asuransi)',
  create_new_order_from_routine_order_list: 'Buat Pesanan Baru dari Daftar Pesanan Rutin',
  customer_central_address_help_field: 'Alamat ini akan ditampilkan sebagai alamat customer saat Anda membuat invoice',
  deactivate_location_content: 'Menonaktifkan lokasi membuat lokasi ini tidak dapat digunakan dalam pengiriman.',
  delete_bill_of_lading_warning: 'Bill of Lading dan semua data terkait (termasuk dokumen dan kontainer) akan dihapus dan Anda tidak bisa mengaksesnya kembali.',
  download_locations_csv_msg: 'Atau unduh templatenya ',
  do_back_button_popup_warning: 'Kembali ke halaman Pesanan Rutin? Anda akan kehilangan isian form yang sudah Anda isi.',
  do_price_not_found_error_msg: 'Harga untuk rute dan pilihan jenis truk ini tidak tersedia. Silahkan mengganti pilihan rute atau jenis truk.',
  enter_verification_code_that_we_sent_to: 'Masukkan kode verifikasi yang kami kirim ke',
  enter_route_to_see_distance: 'Masukkan rute untuk melihat jarak',
  empty_notification: 'Belum ada notifikasi',
  routine_order_empty_title: 'Tambah pesanan rutin untuk membuat pesanan berulang tanpa mengisi formulir dari kosong',
  deactivate_routine_order: 'Nonaktifkan Pesanan Rutin ini?',
  deactivate_routine_content: 'Menonaktifkan akan menghapus Pesanan Rutin ini dari daftar Pesanan Rutin Anda dan tidak dapat digunakan kembali.',
  errors: {
    unexpected: 'Terjadi kesalahan, Tim Kargo segera menyelidiki hal ini',
    backend: {
      acceptDeliveryOrder: {
        9: 'Mohon maaf, pengiriman ini tidak tersedia karena sudah diterima'
      },
      approveDofRequest: {
        83: 'Pengajuan pembayaran cepat tidak terdaftar di sistem',
        84: 'Satu atau beberapa pengiriman yang ditolak bukan bagian dari pengajuan pembayaran ini'
      },
      assignPlannedShipment: {
        108: 'Shipment is not assignable(current status is not PLANNED)',
        131: 'Company Vehicle has ongoing shipment',
        132: 'Company Driver has ongoing shipment'
      },
      assignShipperSalesAccess: {
        465: 'Email sudah diambil di dalam perusahaan'
      },
      common: {
        401: 'Anda tidak memiliki izin',
        500: 'Terjadi kesalahan internal',
        '018-5500': 'Terjadi kesalahan internal'
      },
      createBillOfLading: {
        94: 'No. Bill of Lading sudah pernah digunakan sebelumnya'
      },
      createFirstMileInvoice: {
        422: 'Nomor invoice sudah ada di sistem, nomor invoice harus unik'
      },
      createSmeSales: {
        465: 'Email sudah diambil di dalam perusahaan'
      },
      editBillOfLading: {
        94: 'No. Bill of Lading sudah pernah digunakan sebelumnya',
        422: 'Store tidak bisa diubah'
      },
      generateCompanyHandles: {
        34: 'Harap gunakan hanya alfa numerik dan huruf kecil'
      },
      network: {
        common: 'Periksa kembali koneksi internet Anda',
        500: 'Terjadi kesalahan internal'
      },
      resetPassword: {
        18: 'Kode verifikasi yang anda masukkan tidak sesuai'
      },
      saveBrokerageVendor: {
        34: 'Harap gunakan hanya alfa numerik dan huruf kecil'
      },
      saveCompanyDetails: {
        34: 'Harap gunakan hanya alfa numerik dan huruf kecil'
      },
      saveCompanyVehicle: {
        1: 'Perusahaan tidak terdaftar',
        2: 'Armada belum tidak terdaftar',
        3: 'Gagal mengunggah STNK',
        4: 'Gagal mengunggah KIR',
        14: 'Armada sudah terdaftar',
        15: 'Tipe kendaraan tidak valid'
      },
      saveCompanyVehicles: {
        422: 'Ada beberapa data yang tidak valid'
      },
      saveCustomer: {
        1: 'Perusahaan tidak terdaftar',
        5: 'Perusahaan customer tidak terdaftar'
      },
      saveDeliveryOrder: {
        53: 'Lokasi bongkar tidak valid',
        54: 'Lokasi muat tidak valid',
        63: 'Tanggal bongkar tidak valid',
        66: 'Customer tidak valid',
        422: 'Invalid CSV parameter/attributes',
        482: 'Hmm you need to input this field if it is a single shipment type',
        483: 'Input a date and time format using DD-MM-YYYY HH:MM',
        484: 'Input an active Location registered in B-TMS',
        485: 'Input an active Location registered in B-TMS',
        486: 'Hmm, tidak ada pengirim dengan nama ini.',
        488: 'Hmm, this truck type doesn\'t have Overnight Fees registered for this Shipper and Shipment Type. Let\'s pick another one.',
        490: 'Hmm..this Location doesnt have a Loading Fee for the chosen Shipper, Truck Type, Body Type, and Shipment Type',
        491: 'Hmm..this Location doesnt have a Unloading Fee for the chosen Shipper, Truck Type, Body Type, and Shipment Type',
        493: 'Deadline datetime must be in DD/MM/YYYY format.',
        494: 'Input a date and time format using DD-MM-YYYY HH:MM',
        497: 'Hmm all Group Loads must be posted to Marketplace, try again with "Yes" or "TRUE"',
        498: 'Hmm Per Unit Price Rate Type cannot be posted to Marketplace, try again with "No" or "FALSE"',
        499: 'Hmm, for a Group Load, number of external identifiers must match the number of shipments (with each external identifier separated by semicolon) or must be left blank; for a non-Group Load, enter one external identifier or leave blank',
        501: 'Posted DO  must have origin or posted price.',
        502: 'Isi harga yang mau Anda posting ke Marketplace atau pakai harga rekomendasi kami.',
        505: 'Hmm, this price_rate_type doesn\'t match the Goods Capacity Unit. Pick "Per Route" (or "Route"), "Per {Capacity Unit}" (or "Capacity Unit")',
        506: 'Cannot post capacity unit.',
        507: 'Input YES/TRUE or NO/FALSE',
        508: 'Only posted DO have max transported bid price',
        509: 'Hmm the value here must match the Max Bid Limit (%)',
        510: 'Only posted DO have max bid limit percentage.',
        512: 'Hmm, enter 1 or leave blank to create a Group Load; enter more than 1 and less than 21 to create a Group Load',
        513: 'Hmm, tipe pengirimannya belum tepat. Pilih "f2w", "w2w", "f2dist", "w2dist", "f2mt", "w2depo", "f2depo", "w2mt", "mt", "gt", "multidrop", "multipickup", atau "multidrop_multipickup".',
        516: 'Hmm, tipe truk ini tidak ada. Pilih tipe truk lain, ya.',
        517: 'Hmm, subtipe truk ini tidak ada. Pilih subtipe truk lain, ya.',
        518: 'Gunakan angka 0 atau angka positif dengan maksimal 8 angka di belakang koma.',
        519: 'Hmm, this capacity_unit doesn\'t seem right. Pick "Kg", "CBM", "Unit"',
        523: 'Hmmm you must input a positive number up to 8 decimal points',
        524: 'Hmmm you must input a number from 0-100',
        525: 'Hmmm you must input a positive number up to 8 decimal points',
        526: 'Hmm, this Shipper doesn\'t have this price_rate_type registered for this shipment. Let\'s register the Shipper Price',
        530: 'Input valid shipment type',
        531: 'Invalid point type',
        532: 'Input a date and time format using YYYY-MM-DD HH:MM:SS',
        533: 'Hmm number of locations inputted must be equal to the number of pickup and dropoff points and they must be unique from each other',
        534: 'Input a Location registered in B-TMS'
      },
      saveEmployee: {
        26: 'Email atau telepon sudah digunakan'
      },
      savePartnershipPricing: {
        1001: 'Transporter Tidak Valid',
        1002: 'Harga Duplikat',
        1003: 'Harga kemitraan tidak ditemukan',
        1004: 'Gagal menghapus harga kemitraan'
      },
      saveWarehouse: {
        491: 'Kode Lokasi sudah digunakan'
      },
      saveWarehouseFee: {
        45: 'Anda sudah punya Biaya Lokasi dengan Tipe Biaya, Tipe Pengiriman, Tipe Truk, dan Subtipe truk yang sama',
        46: 'Anda sudah punya Biaya Lokasi dengan Tipe Biaya, Tipe Pengiriman, Tipe Truk, dan Subtipe truk yang sama'
      },
      uploadContainers: {
        422: 'Invalid container file'
      }
    }
  },
  fill_account_details: 'Isi Detail Akun',
  for_payment_information: 'untuk instruksi pembayaran.',
  general_successfully_saved: '{{name}} berhasil disimpan',
  generic_add: 'Tambah {{name}}',
  generic_deactivate: 'Nonaktifkan {{name}}',
  generic_edit: 'Ubah {{name}}',
  generic_upload: 'Upload {{name}}',
  invalid_bank_account: 'Masukkan nomor rekening yang valid',
  kargo_will_process_your_do: 'Kargo akan memproses pesananan Anda. Mohon menunggu untuk proses konfirmasi.',
  leave_bill_of_lading_form_warning: 'Bill of Lading ini tidak akan tersimpan jika Anda meninggalkan halaman ini sekarang.',
  leave_form_warning: 'Semua informasi yang telah Anda masukkan akan hilang',
  leave_shipment_form_warning: 'Pengiriman ini tidak akan tersimpan jika Anda meninggalkan halaman ini sekarang.',
  leave_this_page_question: 'Tinggalkan halaman ini?',
  login_not_allowed: 'Akun Anda belum terdaftar di Shipper manapun, hubungi Team Leader Anda untuk mendaftarkan Anda ke suatu Shipper',
  logo_upload_helper: 'Logo harus format .jpg atau .png, minimal 300 px.',
  only_kargo_admin_can_edit: 'Hanya admin Kargo yang dapat mengubah informasi ini.',
  otp_required_please_retry: 'Kode verifikasi tidak ditemukan. Silakan ulangi',
  order_according_to_your_regular_order_list: 'Pesan sesuai daftar pesanan rutin Anda',
  no_routine_orders: 'Belum ada Pesanan Rutin.',
  create_routine_order: 'Buat Pesanan Rutin untuk menggunakannya di sini.',
  change_order_route: 'Ubah Pesanan sesuai Pesanan Rutin.',
  replace_order_details_in_routine_order: 'Anda akan mengganti semua detail pesanan sesuai dengan informasi yang ada diPesanan Rutin Anda',
  otp_instruction: 'Ketik password baru, lalu masukkan kode\nverifikasi yang kami kirim ke',
  panthera_do_number_info: 'Nomor DO Panthera ini akan digunakan untuk menampilkan lokasi GPS di TMS Shipper.',
  password_doesnt_match: 'Kedua password yang anda masukkan tidak sesuai',
  password_placeholder: 'Ketik Password',
  password_successfully_changed: 'Password berhasil diubah',
  phone_number_placeholder: 'Ketik Nomor Handphone',
  price_perct_of_item_value: 'Harga 0,1% dari Nilai Barang',
  payment_are_made_manually: 'Pembayaran dilakukan manual.',
  reset_password_success: 'Password Anda berhasil diubah.',
  search_by_name: 'Cari nama',
  send_verification_code_failed: 'send_verification_code_failed',
  send_verification_code_success: 'Kode verifikasi terkirim.',
  shipment_successfully_cancelled: 'Pengiriman berhasil dibatalkan',
  shipment_successfully_saved: 'Pengiriman berhasil disimpan',
  some_file_download_failed: 'Beberapa file tidak dapat diunduh.',
  status_successfully_changed: 'Status berhasil diubah',
  transporter_address_for_invoice_helper: 'Tambah alamat customer yang akan dicantumkan di Invoice.',
  the_total_price_of_your_order: 'Harga Total Pesanan Anda (termasuk Asuransi)',
  user_access_type_description_map: {
    [FIRST_MILE_USER_ACCESS_TYPE.CONSIGNEE]: '-',
    [FIRST_MILE_USER_ACCESS_TYPE.FREIGHT_FORWARDER]: '-',
    [USER_ACCESS_TYPE.FULL_ACCESS]: 'Akses untuk melihat, merubah, melakukan apapun, dan mengelola karyawan lain.',
    [USER_ACCESS_TYPE.RESTRICTED_ACCESS]: 'Akses untuk hanya melihat informasi pengiriman dan pembayaran.',
    [USER_ACCESS_TYPE.DOCUMENT_COLLECTION]:
        'Document Collection: To be updated',
  },
  we_will_send_temporary_password: 'Kami akan mengirimkan password sementara ke nomor handphone {{name}}. {{name}} dapat masuk ke {{systemName}} dan mengganti passwordnya.',
  welcome_to_platform: 'Masuk {{name}}',
  welcome_to_shipper_sales: 'Selamat Datang di Kargo Shipper!',
  welcome_to_shipper_sales_description: 'Silahkan lihat pengiriman untuk memulai mengelola bisnis Anda bersama Kargo Shipper',
};
