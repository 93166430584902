import common from './id_common';
import compose from './id_compose';
import message from './id_message';
import firstMile from './id_first_mile';

export default {
  common,
  compose,
  first_mile: firstMile,
  message,
};
