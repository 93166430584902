import React, { useState, createContext, useCallback, useMemo, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { get, intersection } from 'lodash-es';

import { AuthContext } from '@kargotech/tms-core/auth';
import { USER_ACCESS_TYPE } from '~/Configurations/constants';
import { getUserProfile } from '~/Models/userProfile';
import PROFILE from '~/GraphQL/ProfileService/Queries/profile';
import { APOLLO_CLIENTS } from '../Services/apollo';
import sentry from '~/Services/sentry';
import tracker from '~/Services/tracker';

export const ProfileContext = createContext();

function ProfileProvider({ children }) {
  const { selectedCompanyKsuid } = useContext(AuthContext);
  const [profile, setProfile] = useState(getUserProfile(null));

  const resetProfile = useCallback(() => setProfile(getUserProfile(null)), []);

  const getSelectedCompany = useCallback(() => {
    const { ksuid, ...restProfileAttrrs } = profile?.company || {};
    return {
      ksuid: selectedCompanyKsuid || ksuid,
      ...restProfileAttrrs
    };
  }, [profile, selectedCompanyKsuid]);

  const { refetch: getProfile, loading: isFetchingProfile } = useQuery(PROFILE, {
    client: APOLLO_CLIENTS.PROFILE,
    fetchPolicy: 'no-cache',
    skip: !selectedCompanyKsuid,
    variables: {
      companyKsuid: selectedCompanyKsuid,
    },
    onCompleted: profileData => {
      const formattedProfileData = getUserProfile(profileData);
      setProfile(formattedProfileData);

      // set tracker user context
      tracker.profile({
        Site: {
          Name: formattedProfileData.name,
          Identity: formattedProfileData.ksuid || get(formattedProfileData, 'company.email'),
          Email: formattedProfileData.email,
          Phone: formattedProfileData.phoneNumber,
          Company: get(formattedProfileData, 'company.name'),
        }
      });

      // set sentry user context
      sentry.setUser({
        id: formattedProfileData.ksuid,
        email: formattedProfileData.email,
        companyId: formattedProfileData.company.ksuid,
      });
    },
    onError: resetProfile
  });

  const getCompanyMetadata = useCallback(() => {
    const { metadata } = getSelectedCompany();
    if (!metadata || typeof metadata !== 'string') {
      return undefined;
    }

    try {
      return JSON.parse(metadata);
    } catch {
      return undefined;
    }
  }, [getSelectedCompany]);

  const getFirstMileCompany = useCallback(
    () => {
      try {
        /* eslint-disable camelcase */
        const {
          is_first_mile,
          is_first_mile_transporter,
          ...otherProps
        } = getCompanyMetadata() || {};

        if (is_first_mile) {
          return {
            isFirstMileShipper: true,
            ...otherProps
          };
        }

        if (is_first_mile_transporter) {
          return {
            isFirstMileTransporter: true,
            ...otherProps
          };
        }
        /* eslint-enable camelcase */

        return null;
      } catch (_) {
        return null;
      }
    },
    [getCompanyMetadata]
  );

  const isFirstMileShipper = useMemo(() => {
    const firstMileCompany = getFirstMileCompany();
    return Boolean(firstMileCompany && firstMileCompany.isFirstMileShipper);
  }, [getFirstMileCompany]);

  const isFirstMileTransporter = useMemo(() => {
    const firstMileCompany = getFirstMileCompany();
    return firstMileCompany && firstMileCompany.isFirstMileTransporter;
  }, [getFirstMileCompany]);

  /**
   * Check logged-in user roles are matching with allowed roles.
   * Logged-in user roles is stored in localStorage under selectedCompany.accessTypes property.
   * Allowed roles should be any value from ACCESS_PRIVILEGE.
   * The logged-in user roles will be intersecting with the provided Allowed roles excluding RESTRICTED_ACCESS
   * User is authorized if there's one or more matching roles
   * @require src/Configurations/accessPrevillegeMap.js#ACCESS_PRIVILEGE
   * @example
   * isAuthorizedToAccess(ACCESS_PRIVILEGE.SHIPMENT_READ);
   * @param {String[]} roles must-have roles
   * @returns {Boolean} is user authorized or not
   */
  const isAuthorizedToAccess = useCallback(
    (roles = []) => {
      const allowedRoles = roles.filter(role => role !== USER_ACCESS_TYPE.RESTRICTED_ACCESS);
      const userAccessTypes = getSelectedCompany()
        .accessTypes?.filter(accessType => accessType !== USER_ACCESS_TYPE.RESTRICTED_ACCESS) || [];

      return !!intersection(allowedRoles, userAccessTypes).length;
    },
    [getSelectedCompany]
  );

  return (
    <ProfileContext.Provider
      value={{
        getCompanyMetadata,
        getFirstMileCompany,
        getProfile,
        getSelectedCompany,
        isAuthorizedToAccess,
        isFetchingProfile,
        isFirstMileShipper,
        isFirstMileTransporter,
        profile,
        resetProfile,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
}

export default ProfileProvider;
