import {
  USER_ACCESS_TYPE,
  FIRST_MILE_USER_ACCESS_TYPE,
  DANONE_USER_ACCESS_TYPE,
} from '~/Configurations/constants';

const {
  CONSIGNEE,
  FREIGHT_FORWARDER
} = FIRST_MILE_USER_ACCESS_TYPE;

const {
  DANONE_LOGISTIC,
  DANONE_PROCUREMENT
} = DANONE_USER_ACCESS_TYPE;

const {
  ADMIN,
  AR,
  DOCUMENT_COLLECTION,
  FINANCE,
  FULL_ACCESS,
  RESTRICTED_ACCESS,
  SALES_SUPPORT,
  TRAFFIC_CONTROLLER,
  ACCOUNT_PAYABLE,
  TRANSPORT_PLANNER,
  FINANCE_ADMIN
} = USER_ACCESS_TYPE;

// First Mile Project Specific
const FIRST_MILE_ACCESS_PRIVILEGE = {
  CONTAINER_READ: [CONSIGNEE, FREIGHT_FORWARDER],
  CONTAINER_WRITE: [FREIGHT_FORWARDER],
  FIRST_MILE_COMPANY_CONSTANT_READ: [CONSIGNEE, FREIGHT_FORWARDER],
  FIRST_MILE_COMPANY_CONSTANT_WRITE: [FREIGHT_FORWARDER],
  BILL_OF_LADING_READ: [CONSIGNEE, FREIGHT_FORWARDER],
  BILL_OF_LADING_WRITE: [FREIGHT_FORWARDER],
  DELIVERY_READ: [CONSIGNEE, FREIGHT_FORWARDER],
  DELIVERY_WRITE: [FREIGHT_FORWARDER],
  FIRST_MILE_WAREHOUSE_READ: [FREIGHT_FORWARDER],
  FIRST_MILE_WAREHOUSE_WRITE: [FREIGHT_FORWARDER],
  REPORT_READ: [FREIGHT_FORWARDER, CONSIGNEE],
  FIRST_MILE_INVOICE_WRITE: [FREIGHT_FORWARDER],
  FIRST_MILE_INVOICE_READ: [FREIGHT_FORWARDER, CONSIGNEE]
};

/**
 * List of different user's permission mapped from the user's role
 *
 * Here are the list of roles with each explanation
 * ADMIN: Admin for the whole TMS
 * FULL_ACCESS & RESTRICTED_ACCESS: Roles from TTMS
 * FINANCE, SALES, SALES_SUPPORT, VENDOR_MANAGEMENT, AR: Different basic roles from a company for BTMS
 *
 * **Custom Roles**
 * DANONE_LOGISTIC & DANONE_PROCUREMENT
 */
export const ACCESS_PRIVILEGE = {
  SHIPMENT_READ: [
    ADMIN,
    DANONE_LOGISTIC,
    DANONE_PROCUREMENT,
    DOCUMENT_COLLECTION,
    FULL_ACCESS,
    RESTRICTED_ACCESS,
    TRAFFIC_CONTROLLER,
    FINANCE,
    FINANCE_ADMIN,
    ACCOUNT_PAYABLE,
  ],
  SHIPMENT_WRITE: [FULL_ACCESS, RESTRICTED_ACCESS, SALES_SUPPORT, DANONE_LOGISTIC],
  PRICE_READ: [ADMIN, FULL_ACCESS, RESTRICTED_ACCESS],
  PRICE_WRITE: [ADMIN, FULL_ACCESS, SALES_SUPPORT],
  SHIPMENT_COMPANY_CONSTANT_READ: [ADMIN, FULL_ACCESS, RESTRICTED_ACCESS, DANONE_LOGISTIC, DANONE_PROCUREMENT],
  SHIPMENT_COMPANY_CONSTANT_WRITE: [FULL_ACCESS, RESTRICTED_ACCESS],
  VEHICLE_TRACKING_READ: [ADMIN, FULL_ACCESS, RESTRICTED_ACCESS],
  COMPANY_DETAILS_READ: [
    FULL_ACCESS,
    FINANCE,
    FINANCE_ADMIN,
    ACCOUNT_PAYABLE,
    RESTRICTED_ACCESS,
    DANONE_LOGISTIC,
    DANONE_PROCUREMENT
  ],
  COMPANY_DETAILS_WRITE: [FULL_ACCESS],
  EMPLOYEES_READ: [ADMIN, FULL_ACCESS, FINANCE, RESTRICTED_ACCESS, DANONE_LOGISTIC, DANONE_PROCUREMENT],
  EMPLOYEES_WRITE: [FULL_ACCESS],
  BIDDING_READ: [RESTRICTED_ACCESS],
  BIDDING_WRITE: [FULL_ACCESS, RESTRICTED_ACCESS],
  VEHICLES_READ: [ADMIN, FULL_ACCESS, FINANCE, RESTRICTED_ACCESS, TRAFFIC_CONTROLLER, DOCUMENT_COLLECTION],
  VEHICLES_WRITE: [FULL_ACCESS, RESTRICTED_ACCESS, FINANCE],
  VEHICLES_TRACKING_UPDATE: [FULL_ACCESS, TRAFFIC_CONTROLLER],
  DRIVERS_READ: [ADMIN, FULL_ACCESS, FINANCE, RESTRICTED_ACCESS],
  DRIVERS_WRITE: [FULL_ACCESS, RESTRICTED_ACCESS, FINANCE],
  CUSTOMERS_READ: [ADMIN, FULL_ACCESS, RESTRICTED_ACCESS, FINANCE, FINANCE_ADMIN, ACCOUNT_PAYABLE, AR, SALES_SUPPORT],
  CUSTOMERS_INVOICE_REMINDER_UPDATE: [FULL_ACCESS, FINANCE, AR],
  CUSTOMERS_CREATE: [FULL_ACCESS, RESTRICTED_ACCESS, FINANCE],
  CUSTOMERS_UPDATE: [ADMIN, FULL_ACCESS, RESTRICTED_ACCESS, FINANCE],
  VENDORS_READ: [ADMIN, FULL_ACCESS, RESTRICTED_ACCESS, DANONE_LOGISTIC, DANONE_PROCUREMENT],
  VENDORS_WRITE: [FULL_ACCESS, RESTRICTED_ACCESS, DANONE_PROCUREMENT],
  VENDOR_CONTRACTS_READ: [ADMIN, FULL_ACCESS, RESTRICTED_ACCESS, DANONE_PROCUREMENT],
  VENDOR_CONTRACTS_WRITE: [FULL_ACCESS, RESTRICTED_ACCESS, DANONE_PROCUREMENT],
  WAREHOUSES_READ: [ADMIN, FULL_ACCESS, RESTRICTED_ACCESS, FINANCE, DANONE_LOGISTIC, DANONE_PROCUREMENT],
  WAREHOUSES_WRITE: [FULL_ACCESS, RESTRICTED_ACCESS, FINANCE, DANONE_LOGISTIC, DANONE_PROCUREMENT],
  PAYMENT_READ: [ADMIN, FULL_ACCESS, FINANCE, FINANCE_ADMIN, ACCOUNT_PAYABLE],
  PAYMENT_WRITE: [FULL_ACCESS, FINANCE, FINANCE_ADMIN, ACCOUNT_PAYABLE],
  PAYMENT_REQUEST_REVISE: [FULL_ACCESS, ADMIN, ACCOUNT_PAYABLE, FINANCE, FINANCE_ADMIN],
  PAYMENT_REQUEST_APPROVE: [FULL_ACCESS, ADMIN, FINANCE, FINANCE_ADMIN],
  CREATE_PAYMENT_REQUEST: [ADMIN, FULL_ACCESS, FINANCE, FINANCE_ADMIN, ACCOUNT_PAYABLE],
  DISBURSEMENT: [FULL_ACCESS, ADMIN, FINANCE, FINANCE_ADMIN],
  SHIPMENT_CSV_DOWNLOAD: [ADMIN],
  DO_CREATE: [ADMIN, FULL_ACCESS, SALES_SUPPORT],
  DO_UPDATE: [ADMIN, FULL_ACCESS, SALES_SUPPORT],
  DO_BID_PRICE_UPDATE: [FULL_ACCESS, DANONE_PROCUREMENT],
  DO_STATUS_UPDATE: [ADMIN, FULL_ACCESS, TRAFFIC_CONTROLLER],
  PRIVATE_MARKET_PRICING_SETTINGS: [FULL_ACCESS, RESTRICTED_ACCESS],
  POD_CREATE: [ADMIN, FULL_ACCESS, DOCUMENT_COLLECTION],
  POD_UPDATE: [ADMIN, FULL_ACCESS, DOCUMENT_COLLECTION],
  TRANSPORTER_DATA_VIEW: [ADMIN],
  SHIPPER_COPYWRITE: [ADMIN],
  PERSONAL_PHONE_NUMBER_READ: [FULL_ACCESS, RESTRICTED_ACCESS, FINANCE],
  TRANSPORTER_LIST_READ: [ADMIN],
  APPROVE_REASSIGNMENT: [FULL_ACCESS],
  SHIPPER_SWIFT_PAYMENT_WRITE: [ADMIN],
  SWIFT_PAYMENT_BYPASS: [ADMIN],
  SWIFT_PAYMENT_APPLY: [FULL_ACCESS, FINANCE],
  REVIEW_DOF_REQUEST: [ADMIN],
  DISBURSE_DOF_REQUEST: [ADMIN],
  REVIEW_PODF_REQUEST: [ADMIN],
  DISBURSE_PODF_REQUEST: [ADMIN],
  DISBURSEMENT_SCHEME: [ADMIN],
  LOCATION_FEE_WRITE: [FULL_ACCESS, SALES_SUPPORT],
  INSIGHTS_READ: [FULL_ACCESS, DANONE_LOGISTIC, DANONE_PROCUREMENT, AR, ACCOUNT_PAYABLE, FINANCE, FINANCE_ADMIN],
  RECEIVABLE_READ: [AR, ACCOUNT_PAYABLE, DOCUMENT_COLLECTION, FINANCE, FINANCE_ADMIN, FULL_ACCESS],
  RECEIVABLE_WRITE: [AR, ACCOUNT_PAYABLE, DOCUMENT_COLLECTION, FINANCE, FINANCE_ADMIN, FULL_ACCESS],
  SHIPMENT_ACTIONS_BROKERAGE: [ADMIN, FULL_ACCESS, TRANSPORT_PLANNER],
  BROKERAGE_SALES_WRITE: [ADMIN, FULL_ACCESS],
  SHIPMENT_EXTERNALID_SOSTO_UPDATE: [FULL_ACCESS, DANONE_LOGISTIC],
  MULTIPLE_DO_SELECTION: [FULL_ACCESS, DANONE_LOGISTIC],
  ...FIRST_MILE_ACCESS_PRIVILEGE
};

export default {};
