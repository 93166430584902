import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { debounce } from 'lodash-es';
import platformConfig from '~/Configurations/platform';
import Sentry from './sentry';

const { lang: defaultLang } = platformConfig;
// eslint-disable-next-line import/no-dynamic-require
const { default: translations } = require(`~/Translations/${defaultLang}`);

// Adding a 2s timeout so that it can group errors
// at first run and after the 1st api call (approx).
const DEBOUNCE_TIMEOUT = 2000;
const missingKeyErrors = new Set();

const flushMissingKeyError = debounce(() => {
  const missingKeyErrorsStr = Array.from(missingKeyErrors).filter(key => !i18n.exists(key)).join('\n');
  if (!missingKeyErrorsStr) {
    return;
  }

  if (process.env.NODE_ENV !== 'production') {
    // Break the app to increase awareness during development
    throw new Error(`Missing translation key:\n${missingKeyErrorsStr}`);
  }

  if (process.env.NODE_ENV === 'production') {
    Sentry.captureException(new Error('Missing translation key'), {
      extra: {
        missing_keys: Array.from(missingKeyErrors),
      },
    });
  }
  missingKeyErrors.clear();
}, DEBOUNCE_TIMEOUT);

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: defaultLang,
    fallbackLng: defaultLang,
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      [defaultLang]: translations
    },
    saveMissing: true,
    missingKeyHandler(lngs, namespace, key) {
      missingKeyErrors.add(`${namespace}:${key}`);
      flushMissingKeyError();
    }
  });

export default i18n;
