import * as Sentry from '@sentry/react';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    sampleRate: process.env.REACT_APP_ENVIRONMENT === 'PRD' ? 1 : 0.1,
    maxBreadcrumbs: 20
  });
}

export default Sentry;
