import React, { Fragment, useCallback, useContext, useEffect } from 'react';
import { Route as ReactRoute, Redirect, useLocation, useHistory } from 'react-router-dom';

import { PageSuspenseFallback } from '@kargotech/tms-ui/atoms';
import { AUTH_STATUS, AuthContext } from '@kargotech/tms-core/auth';
import { ProfileContext } from '../../Contexts/ProfileProvider';
import { NavigationContext } from '../../Contexts/NavigationProvider';

/**
 * Private Route: can only be accessed by authenticated user
 * @param {Object} props
 * @param {import('react').ReactElement} props.children
 * @param {import('react').ReactNode} props.Layout
 * @param {String} props.title
 * @param {String} props.subtitle
 * @param {Object[]} props.tabNavigation
 * @param {Boolean} props.skipTabNavigationSetup
 * @param {String} props.tabNavigation.path
 * @param {String} props.tabNavigation.title
 */
function PrivateRoute({
  children,
  tabNavigation,
  Layout = Fragment,
  title,
  subtitle,
  skipTabNavigationSetup = false,
  ...routeProps
}) {
  const {
    authStatus,
    enrolledCompanies,
    isFirstTimeLogin,
    updateSessionStatus,
    selectedCompanyKsuid,
    fetchAndSaveEnrolledCompanies
  } = useContext(AuthContext);
  const { getProfile, isFetchingProfile, resetProfile } = useContext(ProfileContext);
  const {
    setTitle,
    setSubtitle,
    setTabNavigation
  } = useContext(NavigationContext);

  const { pathname, search, state } = useLocation();
  const history = useHistory();

  useEffect(() => {
    updateSessionStatus();
  }, [updateSessionStatus]);

  useEffect(() => {
    if (title !== undefined) {
      setTitle(title || '');
    }
    if (subtitle !== undefined) {
      setSubtitle(subtitle || '');
    }
    if (!skipTabNavigationSetup) {
      setTabNavigation(tabNavigation || []);
    }
  }, [title, setTitle, subtitle, setSubtitle, tabNavigation, setTabNavigation, skipTabNavigationSetup]);

  useEffect(() => {
    if (authStatus === AUTH_STATUS.AUTHENTICATED && selectedCompanyKsuid) {
      getProfile();
    }

    if (authStatus === AUTH_STATUS.NOT_AUTHENTICATED) {
      resetProfile();
    }
  }, [authStatus, getProfile, resetProfile, selectedCompanyKsuid]);

  useEffect(() => {
    if (isFirstTimeLogin) {
      history.replace('/welcome');
    }
  }, [isFirstTimeLogin, history]);

  const updateSessionAndEnrolledCompanies = useCallback(async () => {
    const isAuthenticated = await updateSessionStatus();
    if (isAuthenticated) {
      await fetchAndSaveEnrolledCompanies();
    }
  }, [fetchAndSaveEnrolledCompanies, updateSessionStatus]);

  useEffect(() => {
    updateSessionAndEnrolledCompanies();
  }, [updateSessionAndEnrolledCompanies]);

  const wrapRender = useCallback(renderedEl => (
    <ReactRoute {...routeProps}>
      {renderedEl}
    </ReactRoute>
  ), [routeProps]);

  // Need to wait until the user is properly authenticated
  // AND the profile is completely fetched (to handle default redirection
  // for specific use case. Currently used in first mile)
  if (authStatus === AUTH_STATUS.LOADING || isFetchingProfile) {
    return wrapRender(
      <PageSuspenseFallback />
    );
  }

  if (authStatus === AUTH_STATUS.NOT_AUTHENTICATED || !selectedCompanyKsuid) {
    return wrapRender(
      <Redirect
        to={{
          pathname: '/auth/login',
          state: { from: pathname, fromSearch: search }
        }}
      />
    );
  }

  if (authStatus === AUTH_STATUS.AUTHENTICATED && !selectedCompanyKsuid && enrolledCompanies.length > 1) {
    history.replace('/auth/choose-company', state);
    return null;
  }

  return wrapRender(
    <Layout>
      {children}
    </Layout>
  );
}

export default PrivateRoute;
